import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,useParams, Link} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { FaAngleDoubleLeft } from "react-icons/fa";

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const { Option } = Select;
const { TextArea } = Input;

const LeadsProcessed = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const venueId = queryParams.get('venue_id');

  var month = JSON.parse(localStorage.getItem('month'));
  var year = JSON.parse(localStorage.getItem('year'));

  const [loading, setLoading] = useState(true)
  const [selectedVenueId, setSelectedVenueId] = useState(venueId)
  const [venueName, setVenueName] = useState('')
  const [leadData, setLeadData] = useState([])
  const [header, setHeader] = useState([])

  useEffect(() => {
    setLoading(true)
    getLeadsDayByDay()
    localStorage.setItem('selectedVenueId',JSON.stringify(selectedVenueId))
  }, [selectedVenueId]); 

  const getLeadsDayByDay= () => {
    var date = year + '-' + month + '-01'
    getData(`/eagleeye/getTableData/?report_id=76377&venue_id=${selectedVenueId}&date=${date}`)
    .then(response =>{
      setHeader(response.headers)
      setLeadData(response.data)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const columns = header.map((header, index) => ({
    title: header,
    dataIndex: index.toString(),
    key: header,
    align: 'center',
    render: (text) => {
        const linkMatch = text.match(/<a .*?href="(.*?)".*?>(.*?)<\/a>/);
        
        if (linkMatch) {
            const href = linkMatch[1];
            const linkText = linkMatch[2];
            
            return (
                <Link className="venueTitle" to={href}>
                    {linkText}
                </Link>
            );
        }

        return <span className="venueTitle">{text}</span>;
    }
  }));  

  const rowData = leadData.map((row, rowIndex) => {
      const rowObj = {};
      row.forEach((cell, cellIndex) => {
          rowObj[cellIndex] = cell;
      });
      return { key: rowIndex, ...rowObj };
  });

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.Header active={true}>
          <a style={{cursor: 'pointer',color:'#02475c'}} onClick={()=>navigate('/leads')}><FaAngleDoubleLeft/></a>
           <a>Leads Processed </a>
           <a></a>
        </Styled.Header>
        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns} 
          dataSource={rowData}
          pagination={false}
         />

      </Styled.MainContainer>

    </Spin>
  );
}

export default LeadsProcessed;