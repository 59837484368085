import React, {useState, useRef, useEffect} from "react";
import * as Styled from './style.js';
import VenueSelector from '../../components/venueSelector'

import { InfoCircleOutlined,UpOutlined, CheckCircleTwoTone, SwapOutlined, DownOutlined, WarningTwoTone, CloseOutlined, FilterOutlined,MoreOutlined,ArrowRightOutlined,ExclamationCircleOutlined,EditOutlined } from '@ant-design/icons';
import { Popover, Popconfirm, DatePicker, Space, Button, Dropdown, Empty, Alert, Input, Table, Tag, Checkbox, Modal, Drawer, Cascader, Select, Segmented } from 'antd';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

import moment from 'moment';
import { postData,getData } from '../../config/api-connect';

const { RangePicker } = DatePicker;
const { Search } = Input;
const { SHOW_CHILD } = Cascader;

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#cb00fe', '#8c028c', '#FF34B3', '#00FF7F', '#FFA500', '#8A2BE2', '#0088FE', '#bbbbbb'];

const pieGraphData = [{"name": "Cash","value": 22625},{ "name": "Phonepe", "value":  21750}, {"name": "Razorpay", "value": 33834}, {"name": "Bank", "value": 3000}, {"name": "Others", "value": 2400}, ]

const popoverExcludeContent = (
  <div style={{fontSize: 'small'}}>
    <div>All collections from razorpay, playo and instamojo</div>
    <div>All collections of kit fee, equipment rental fee, and direct cost</div>
    <div>All collections from The Majesstine Sports</div>
  </div>
)

const popoverExcludeTitle = (
  <div style={{fontWeight: 'bold'}}>Below mentioned items are excluded by default from the list</div>
)

const options = [
  {
    value: 'payment_mode',
    label: 'Payment Mode',
    children: [
      {
        value: 'razorpay',
        label: 'Razorpay',
      },
      {
        value: 'phonepe',
        label: 'Phonepe',
      },
      {
        value: 'cash',
        label: 'Cash',
      },
      {
        value: 'bank',
        label: 'Bank',
      },
      {
        value: 'others',
        label: 'Others',
      },
    ],
  },
  {
    value: 'payment_source',
    label: 'Payment Source',
    children: [
      {
        value: 'website',
        label: 'Website',
      },
      {
        value: 'app',
        label: 'App',
      },
      {
        value: 'partner',
        label: 'Partner',
      },
    ],
  },
  {
    value: 'status',
    label: 'Status',
    children: [
      {
        value: 'verified',
        label: 'Verified',
      },
      {
        value: 'not_verified',
        label: 'Not Verified',
      },
      {
        value: 'transaction_id_missing',
        label: 'Transaction ID missing',
      },
    ],
  },
  {
    value: 'coupon_code',
    label: 'Coupon Code',
    children: [
      {
        value: 'blaster25',
        label: 'BLASTER25',
      },
      {
        value: 'blaster50',
        label: 'BLASTER50',
      },
      {
        value: 'blaster75',
        label: 'BLASTER75',
      },
    ],
  },
];



function Collection() {

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const firstDayOfMonth = `${year}-${month}-01`;
  const firstDayOfYear = `${year}-01-01`;

  const [allLocationFilter,setAllLocationFilter] = useState(false)
  const [keralaLocationFilter,setKeralaLocationFilter] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedDateChoice, setSelectedDateChoice] = useState('This month')
  const [openDateMenu, setOpenDateMenu] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(firstDayOfMonth)
  const [selectedEndDate, setSelectedEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [collectionData, setCollectionData] = useState([])
  const [filteredCollectionIds, setFilteredCollectionIds] = useState([])
  const [pendingCollectionToVerify, setPendingCollectionToVerify] = useState(0)
  const [selectedTableRow, setSelectedTableRow] = useState([])
  const [editedTransactionID, setEditedTransactionID] = useState('')
  const [searchCustomer, setSearchCustomer] = useState('')
  const [filterOptions, setFilterOptions] = useState([])
  const [appliedFilters, setAppliedFilters] = useState([])
  const [paymentsWithoutTransID, setPaymentsWithoutTransID] = useState('')
  const [notVerifiedCollection, setNotVerifiedCollection] = useState('')
  const [paymentModeSplitup, setPaymentModeSplitup] = useState([])
  const [paymentSourceSplitup, setPaymentSourceSplitup] = useState([])
  const [tempLoc, setTempLoc] = useState('Bangalore')
  const [tempCollectionStore, setTempCollectionStore] = useState([])
  const [tablePage, setTablePage] = useState(1)
  const [tablePageSize, setTablePageSize] = useState(20)
  const [tableTotalPages, setTableTotalPages] = useState(1)
  const [tableLoader, setTableLoader] = useState(false)
  const [updateTransactionIDLoader, setUpdateTransactionIDLoader] = useState(false)
  const [totalEntries, setTotalEntries] = useState(null)
  const [venuelist, setVenuelist] = useState([])
  const [sortSelector, setSortSelector] = useState('oldest first')
  const [expandThisMonth, setExpandThisMonth] = useState(false)
  const [expandLastMonth, setExpandLastMonth] = useState(false)
  const [currentMonthCalenderData, setCurrentMonthCalenderData] = useState([])
  const [lastMonthCalenderData, setLastMonthCalenderData] = useState([])
  const [currentMonthAllVerified, setCurrentMonthAllVerified] = useState(false)
  const [lastMonthAllVerified, setLastMonthAllVerified] = useState(false)
  const [totalPaidSum, setTotalPaidSum] = useState(0)
  const [showUnverfiedCountList, setShowUnverfiedCountList] = useState(false)
  const [showIdMissingCountList, setShowIdMissingCountList] = useState(false)
  const [verifyAllLoader, setVerifyAllLoader] = useState(false)

  const dropdownRef = useRef(null);
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !pickerRef.current.contains(event.target)) {        
        setOpenDateMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
   get_collection_data()
   get_collection_stats()
  },[]);

  useEffect(() => {
   get_verification_status_of_each_day()
  },[tempLoc]);

  useEffect(() => {
   get_collection_data()
   get_collection_stats()
  },[selectedStartDate, selectedEndDate, tempLoc, sortSelector]);


  useEffect(()=>{
    setTablePage(1)
    get_collection_data()
  },[appliedFilters])


  const get_collection_stats = async () => {
    await postData('/finance/get_payment_stats/', {'city_name': tempLoc, 'start_date': selectedStartDate, 'end_date': selectedEndDate})
    .then(response =>{
      changePaymentModeArr(response.payment_mode_stat1, response.payment_mode_stat2)
      setPaymentSourceSplitup(response.payment_source_stat)
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  const get_verification_status_of_each_day = async () => {
    await postData('/finance/get_verification_status_of_each_day/', {'city_name': tempLoc})
    .then(response =>{
      setCurrentMonthCalenderData(response.current_month_data)
      setLastMonthCalenderData(response.last_month_data)
      setMonthlyVerifiedStatus(response.current_month_data, response.last_month_data)
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  const setMonthlyVerifiedStatus = (current_month_data, last_month_data) => {
    var not_verified_this_month = current_month_data.filter(obj=> obj.verified == false)
    var not_verified_last_month = last_month_data.filter(obj=> obj.verified == false)

    if(not_verified_this_month){
      setCurrentMonthAllVerified(false)
    }
    else{
      setCurrentMonthAllVerified(true)
    }

    if(not_verified_last_month){
      setLastMonthAllVerified(false)
    }
    else{
      setLastMonthAllVerified(true)
    }
  }

  const changePaymentModeArr = (data1, data2) => {
    var result = []
    for(var i=0; i<data1.length; i++){
      result.push({'name': data1[i].payment__mode, 'value': data1[i].total_amount})
    }

    for(var i=0; i<data2.length; i++){
      const existing = result.find(item => item.name === data2[i].payment__mode)
      if(existing){
        existing.value += data2[i].total_amount
      }
      else{
        result.push({'name': data2[i].payment__mode, 'value': data2[i].total_amount})  
      }
    }    

    setPaymentModeSplitup(result)
  }

  const get_collection_data = async (page = 1, number = '') => {
    setTableLoader(true)

    if(sortSelector == 'oldest first'){
      var asc = true
    }
    else{
      var asc = false
    }

    await postData('/finance/get_collection_data/', {'city_name': tempLoc, 'start_date': selectedStartDate, 'end_date': selectedEndDate, 'filters': appliedFilters, 'page': page, 'phone_number': number, 'asc': asc})
    .then(response =>{
      setCollectionData(response.result)
      setFilteredCollectionIds(response.filtered_collection_ids)
      setPendingCollectionToVerify(response.collections_pending_to_verify)
      setTempCollectionStore(response.result)
      getFilterValues(response)
      setTableTotalPages(response.num_pages)
      setTablePageSize(response.pageSize)
      setTableLoader(false)
      setTotalEntries(response.total_entries)
      setTotalPaidSum(response.total_amount)
      
      setPaymentsWithoutTransID(response.missing_transaction_id_collections)
      setNotVerifiedCollection(response.notverified_collections)
    })
    .catch(error => {
      console.log(error.message);
      setTableLoader(false)
    });
  }

  const getFilterValues = async (data) => {
    var venue_data = data.filter_venues
    var status_list = data.filter_status
    var paymentmode_list = data.filter_payment_modes

    status_list = [...new Set(status_list)]
    paymentmode_list = [...new Set(paymentmode_list)]

    var status_data=[]
    for(var i=0; i< status_list.length; i++){
      status_data.push({'label': status_list[i], 'value': status_list[i]})
    }

    var mode_data=[]
    for(var i=0; i< paymentmode_list.length; i++){
      if(paymentmode_list[i]){
        mode_data.push({'label': paymentmode_list[i], 'value': paymentmode_list[i]})
      }
    }

    mode_data = [...new Set(mode_data)]
    status_data = [...new Set(status_data)]

    var filter_options = [{'value': 'venue', 'label': 'Venue', 'children': venue_data}, {'value': 'status', 'label': 'Status', 'children': status_data}, {'value': 'payment_mode', 'label': 'Payment Mode', 'children': mode_data}]
    await setFilterOptions(filter_options)
  }



  const handleDateMenuClick = (e) => {
    if (['1', '2', '3'].includes(e.key)) {
      setOpenDateMenu(false);
    }
  };

  const handleOpenDateChange = (flag) => {
    setOpenDateMenu(flag);
  };

  const handleModal = (record) => {
    setIsModalOpen(!isModalOpen);
    setSelectedTableRow(record)
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setSelectedTableRow([])
    setEditedTransactionID('')
  }


  const updateOpenStateAll = () => {
    setAllLocationFilter(!allLocationFilter)
    setKeralaLocationFilter(false)
  }

  const updateOpenStateKerala = () => {
    setAllLocationFilter(false)
    setKeralaLocationFilter(!keralaLocationFilter)
  }

  const updateVerificationTag = async (collection_id) => {
    await postData('/finance/verify_collection/', {'collection_id': collection_id, 'transaction_id': editedTransactionID })
    .then(response =>{
      if(response.success){
        setUpdateTransactionIDLoader(false)
        setVerifyAllLoader(false)
        setIsModalOpen(false)
        setEditedTransactionID('')
        setSelectedTableRow([])
        get_collection_data(tablePage)
        Modal.success({
          content: response.message,
        });
      }
      else{
        setUpdateTransactionIDLoader(false)
        setVerifyAllLoader(false)
        setIsModalOpen(false)
        setEditedTransactionID('')
        setSelectedTableRow([])
        Modal.error({
          content: response.message,
        });
      }
      
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  

  const verifyAll = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to verify pending ${pendingCollectionToVerify} entries of collection ?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        setVerifyAllLoader(true)
        updateVerificationTag(filteredCollectionIds)
      },
    });
  }


  const confirmVerify = (label, collection) => {
    if(label === 'not verified'){
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure to mark this payment as verified ?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          updateVerificationTag(collection.id)
        },
      });
    }
  };

  const handlePickerClick = (event) => {
    event.stopPropagation();
  };

  const setDateChoice = async (val) => {
    setSelectedDateChoice(val)

    var start_date, end_date;
    const today_date = moment().format('YYYY-MM-DD')
    end_date = today_date

    if(val == 'Today'){
      start_date = today_date
    }

    if(val == 'This month'){
      start_date = firstDayOfMonth
    }

    if(val == 'Last month'){
      start_date = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
      end_date = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    }

    await setSelectedStartDate(start_date)
    await setSelectedEndDate(end_date)
    setOpenDateMenu(false)
  }

  const onChangeDate = async (date, dateString) => {
    if(dateString[0] && dateString[1]){
      await setSelectedStartDate(dateString[0])
      await setSelectedEndDate(dateString[1])
      var custom_date = dateString[0] + ' to ' + dateString[1]
      await setSelectedDateChoice(custom_date)
      await setOpenDateMenu(false)
    }
};

const applyCustomDate = () => {
  var custom_date = selectedStartDate + ' to ' + selectedEndDate
  setSelectedDateChoice(custom_date)
  setOpenDateMenu(false)
}

const selectCalenderDate = async (date) => {

  await setSelectedStartDate(date)
  await setSelectedEndDate(date)
  await setSelectedDateChoice(date)
  await setOpenDateMenu(false)
  await setExpandThisMonth(false)
  await setExpandLastMonth(false)
}

const getAllDatesOfMonth = (date_obj) => {
  var year = date_obj.format('YYYY')
  var month = date_obj.format('MM')
  const startDate = moment({ year, month: month - 1 }).startOf('month');
  const endDate = moment({ year, month: month - 1 }).endOf('month');

  const dates = [];
  let currentDate = startDate.clone();
  while (currentDate.isSameOrBefore(endDate, 'day')) {
    dates.push(currentDate.date());
    currentDate.add(1, 'day');
  }

  return dates
}

// <a onClick={()=>setDateChoice('This month')}>
//         This month
//       </a>

  const items = [
  {
    key: '1',
    label: (
      <a onClick={()=>setDateChoice('Today')}>
        Today
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <div onClick={(e) => e.stopPropagation()}>
        <div style={{display: 'grid', gridTemplateColumns: '90% 10%', alignItems: 'center'}}>
          {/*<div> This month <span style={{marginLeft: '5px', backgroundColor: '#c9f9c9', fontSize: 'x-small', padding: '0px 3px', border: '1px solid green', borderRadius: '3px'}}>verified</span> </div>*/}
          <div onClick={()=>setDateChoice('This month')}> 
            This month 
            {
              currentMonthAllVerified ?
                <span style={{marginLeft: '5px', backgroundColor: '#c9f9c9', fontSize: 'x-small', padding: '0px 3px', border: '1px solid green', borderRadius: '3px'}}>verified</span>
              :
                <span style={{marginLeft: '5px', backgroundColor: 'rgb(249 228 201)', fontSize: 'x-small', padding: '0px 3px', border: '1px solid orange', borderRadius: '3px'}}>pending</span> 
            }
          </div>
          <DownOutlined onClick={()=>setExpandThisMonth(!expandThisMonth)} style={{color: 'grey', borderLeft: !expandThisMonth && '1px solid grey', borderRight: expandThisMonth && '1px solid grey', paddingLeft: !expandThisMonth && '5px', paddingRight: expandThisMonth && '5px', height: '70%', transform: expandThisMonth && 'rotate(180deg)'}}/>
        </div>
        {
          expandThisMonth &&
          <div>
            <hr style={{color: 'grey'}} />
            <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto auto auto auto auto', gridRowGap: '5px', gap: '5px'}}>
              {
                currentMonthCalenderData.map(item=>(
                  <Styled.CalenderDate onClick={()=>selectCalenderDate(item.date)} style={{backgroundColor: item.verified && 'green', color: item.verified && 'white'}}>{moment(item.date).format('DD')}</Styled.CalenderDate>
                ))
              }
            </div>
            <hr style={{color: 'grey'}} />
          </div>
        }
        
      </div>
    ),
  },
  {
    key: '3',
    label: (
      <div onClick={(e) => e.stopPropagation()}>
        <div style={{display: 'grid', gridTemplateColumns: '90% 10%', alignItems: 'center'}}>
          {/*<div> This month <span style={{marginLeft: '5px', backgroundColor: '#c9f9c9', fontSize: 'x-small', padding: '0px 3px', border: '1px solid green', borderRadius: '3px'}}>verified</span> </div>*/}
          <div onClick={()=>setDateChoice('Last month')}> 
            Last month 
            {
              lastMonthAllVerified ?
                <span style={{marginLeft: '5px', backgroundColor: '#c9f9c9', fontSize: 'x-small', padding: '0px 3px', border: '1px solid green', borderRadius: '3px'}}>verified</span>
              :
                <span style={{marginLeft: '5px', backgroundColor: 'rgb(249 228 201)', fontSize: 'x-small', padding: '0px 3px', border: '1px solid orange', borderRadius: '3px'}}>pending</span> 
            }
            
          </div>
          <DownOutlined onClick={()=>setExpandLastMonth(!expandLastMonth)} style={{color: 'grey', borderLeft: !expandLastMonth && '1px solid grey', borderRight: expandLastMonth && '1px solid grey', paddingLeft: !expandLastMonth && '5px', paddingRight: expandLastMonth && '5px', height: '70%', transform: expandLastMonth && 'rotate(180deg)'}}/>
        </div>
        {
          expandLastMonth &&
          <div>
            <hr style={{color: 'grey'}} />
            <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto auto auto auto auto', gridRowGap: '5px', gap: '5px'}}>
              {
                lastMonthCalenderData.map(item=>(
                  <Styled.CalenderDate onClick={()=>selectCalenderDate(item.date)} style={{backgroundColor: item.verified && 'green', color: item.verified && 'white'}}>{moment(item.date).format('DD')}</Styled.CalenderDate>
                ))
              }
            </div>
            <hr style={{color: 'grey'}} />
          </div>
        }
        
      </div>
    ),
  },
  {
    key: '4',
    label: (
      <div>
        <hr />
        <div onClick={handlePickerClick} style = {{backgroundColor: 'whitesmoke', padding: '10px', borderRadius: '5px', display: 'flex', flexDirection: 'column'}}>
          <RangePicker ref={pickerRef} onChange={onChangeDate} />
          <Button danger style={{marginTop: '10px'}} onClick={applyCustomDate}>Apply</Button>
        </div>
      </div>
    ),
  },
];

  const clickedARow = (item) => {
    setOpenDrawer(true)
    setSelectedTableRow(item)
  }

  const updateTransactionID = () => {
    if(editedTransactionID){
      if(!Array.isArray(selectedTableRow)){ //if its not array then its trans id update of single collection else its a bulk update
        postTransactionID()  
      }
      else{
        setUpdateTransactionIDLoader(true)
        updateVerificationTag(filteredCollectionIds)
      }
    }
    else{
      alert("Please enter the transaction id")
    }
  }

  const postTransactionID = async () => {
    setUpdateTransactionIDLoader(true)
    await postData('/finance/update_transaction_id/', {'collection_id': selectedTableRow.id, 'transaction_id': editedTransactionID })
    .then(response =>{
      Modal.success({
          content: response.message,
        });
      if(response.success == true){
        setIsModalOpen(false)
        setEditedTransactionID('')
        setSelectedTableRow([])
        get_collection_data(tablePage)
        setUpdateTransactionIDLoader(false)
      }
    })
    .catch(error => {
      console.log(error.message);
      setEditedTransactionID('')
      setSelectedTableRow([])
      setUpdateTransactionIDLoader(false)
    });
  }

  const applyCustomStatusFilter = async(val) => {
    await setAppliedFilters(val)
  }

  const columns = [
  {
    title: 'Paid at',
    dataIndex: 'paid_at',
    key: 'paid_at',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Paid for',
    dataIndex: 'paid_for',
    key: 'paid_for',
  },
  {
    title: 'Name',
    dataIndex: 'customer_name',
    key: 'customer_name',
  },
  {
    title: 'Mobile No',
    dataIndex: 'customer_mobile_no',
    key: 'customer_mobile_no',
  },
  {
    title: 'Venue',
    dataIndex: 'venue',
    key: 'venue',
  },

  {
    title: 'Total paid',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Payment mode',
    dataIndex: 'payment_mode',
    key: 'payment_mode',
  },

  {
    title: 'Transaction ID',
    dataIndex: 'transaction_id',
    key: 'transaction_id',
    render: (item,record) => !item ? <Button type="primary" onClick={()=>handleModal(record)}> Add </Button> : <div style={{cursor: 'pointer', fontSize: 'small'}} onClick={()=>handleModal(record)}><span>{item.length > 5 ? item.replace(/(.{5})/g, "$1 ") : item}</span><div style={{color: 'dimgrey', fontSize: 'smaller', alignItems: 'center', display: 'flex', gap: '3px', marginTop: '3px'}}>( <EditOutlined /> edit )</div></div>
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (item,record) => (
      <>
        {item && item.map((tag, ind) => {
          
          return (
            <div key={ind} style={{marginTop: ind > 0 && '10px', cursor: tag.label === 'not verified' && 'pointer'}} onClick={()=>confirmVerify(tag.label, record)}>
              <Tag color={tag.color} key={tag.label}>
                {tag.label.toUpperCase()}
              </Tag>
              {
                tag.label === 'not verified' &&
                  <div style={{color: 'dimgrey', fontSize: 'smaller'}}>(Tap to change)</div>
              }

            </div>
          );
        })}
      </>
    )
  },
  {
    title: 'More',
    dataIndex: 'more',
    key: 'more',
    render: (item,record) =><a style={{color:'blue'}} onClick={()=>clickedARow(record)}><ArrowRightOutlined /></a>
  },  
];

  const VenueLocationSelector = () =>(
    <div style={{backgroundColor: 'white', padding: '15px', borderRadius: '10px', position: 'absolute', right: 0, width: '50em', boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'}}>
      
      <Search placeholder="Search by venue or city" enterButton style={{}} loading={false} />

      <div style={{marginTop: '15px', display: 'flex', alignItems: 'center', gap: '20px'}}>

        <div style={{display: 'flex', alignItems: 'center', border: '1px solid whitesmoke', borderRadius: '5px', padding: '5px', gap: '10px', backgroundColor: allLocationFilter && 'aliceblue'}}><Checkbox style={{borderRight: '1px solid whitesmoke'}}>All</Checkbox> <DownOutlined style={{cursor: 'pointer', transform: allLocationFilter && 'rotate(180deg)'}} onClick={()=> updateOpenStateAll()} /></div>
        <div style={{display: 'flex', alignItems: 'center', border: '1px solid whitesmoke', borderRadius: '5px', padding: '5px', gap: '10px', backgroundColor: keralaLocationFilter && 'aliceblue'}}><Checkbox style={{borderRight: '1px solid whitesmoke'}}>Kerala</Checkbox> <DownOutlined style={{cursor: 'pointer', transform: keralaLocationFilter && 'rotate(180deg)'}} onClick={()=> updateOpenStateKerala()} /></div>
        <div style={{display: 'flex', alignItems: 'center', border: '1px solid whitesmoke', borderRadius: '5px', padding: '5px', gap: '10px'}}><Checkbox style={{borderRight: '1px solid whitesmoke'}} defaultChecked = {true} disabled>Bangalore</Checkbox> <DownOutlined /></div>
        <div style={{display: 'flex', alignItems: 'center', border: '1px solid whitesmoke', borderRadius: '5px', padding: '5px', gap: '10px'}}><Checkbox style={{borderRight: '1px solid whitesmoke'}} disabled>Hyderabad</Checkbox> <DownOutlined /></div>

      </div>

      {
        allLocationFilter &&

          <div style={{marginTop: '20px', display: 'flex', flexDirection: 'column', padding: '10px', backgroundColor: 'aliceblue'}}>

            <Checkbox>All sports centers</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>All schools</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>All apartments</Checkbox>

          </div>
      }

      {
        keralaLocationFilter &&

          <div style={{marginTop: '20px', display: 'flex', flexDirection: 'column', padding: '10px', backgroundColor: 'aliceblue'}}>

            <Checkbox>Trivandrum</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>Kollam</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>Ernakulam</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>Palakkad</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>Kannur</Checkbox>

          </div>
      }

      <hr />

      <div style={{backgroundColor: 'rgb(249 249 249)', display: 'flex', justifyContent: 'space-between', padding: '10px', borderRadius: '5px', alignItems: 'center'}}>

        <div>Selected Filter : <Tag closable color="magenta" style={{marginLeft: '5px'}}> Bangalore </Tag></div>
        <Button type="primary">Apply</Button>

      </div>
      

    </div>

  )

  const onChangeNumberSearch = async (val) => {
    await setSearchCustomer(val)
    if(!val || val.length == 0){
      setTablePage(1)
      get_collection_data(1, val)
    }
  }

  const onSearchCustomer = (val) => {
    
    if((isNaN(val) || val.length != 10) && val.length > 0){
      Modal.warning({
        content: 'Please enter a valid phone number',
      });
    }
    else{
      setTablePage(1)
      get_collection_data(1, val)
    }
  }

  const calcPaymentSum = () => {
    var sum = 0
    for(var i=0; i<paymentModeSplitup.length; i++){
      sum = sum + paymentModeSplitup[i].value
    }

    return Math.round(sum).toLocaleString('en-IN')
  }

  const calcPaymentSourceSum = () =>{
    var sum = 0
    for(var i=0; i<paymentSourceSplitup.length; i++){
      sum = sum + paymentSourceSplitup[i].total_amount
    }
    return Math.round(sum)
  }

  const onChangeFilter = (value) => {
    setAppliedFilters(value)
    if((!value || value.length == 0) && value == appliedFilters){
       setTablePage(1)
       get_collection_data()
    }
  }

  const applyFilters = () => {
    setTablePage(1)
    get_collection_data()  
  }

  const onChangePage = async (page) => {
    setTablePage(page.current)
    get_collection_data(page.current)
  }

  const filterCascader = (inputValue, path) => {
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)  
  }

  const onVerifyWithTrans = () => {
    setSelectedTableRow([])
    setIsModalOpen(!isModalOpen)
  }
  

  return (
    <Styled.MainContainer ref={dropdownRef}>

      <Styled.MainHeader>

        <h5> Collection Verification </h5>

        <Space size = {15}>


          <Styled.DropdownContainer>

            <Select
              bordered={false}
              defaultValue={tempLoc}
              style={{
                minWidth: 120
              }}
              onChange={(value)=>setTempLoc(value)}
              options={[
                // {
                //   value: 'all',
                //   label: 'All',
                // },
                {
                  value: 'Bangalore',
                  label: 'Bangalore',
                },
                {
                  value: 'Kerala',
                  label: 'Kerala',
                },
                {
                  value: 'Hyderabad',
                  label: 'Hyderabad',
                },
              ]}
            />

          </Styled.DropdownContainer>


          <Styled.DropdownContainer style={{minWidth: 120, height: '3em', display: 'flex'}}>

            <Dropdown menu={{ items, onClick: handleDateMenuClick }} placement="bottomRight" arrow trigger={['click']} open={openDateMenu} onOpenChange={handleOpenDateChange}>
               <Styled.DropdownSelector onClick={(e) => e.preventDefault()}>
                 <span>{selectedDateChoice}</span>
                 <DownOutlined />
               </Styled.DropdownSelector>
            </Dropdown>
          </Styled.DropdownContainer>

        </Space>

      </Styled.MainHeader>



      <Modal title="Update Transaction ID" open={isModalOpen} closable={false}
      footer={[
          <Button key="back" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={updateTransactionID} loading = {updateTransactionIDLoader}>
            Submit
          </Button>,
        ]}
      >
        <Input placeholder="enter transaction id" value={editedTransactionID} onChange={(e)=>setEditedTransactionID(e.target.value)} />
      </Modal>


      <Drawer
        title="More Details"
        closable={false}
        headerStyle={{backgroundColor: '#052e67'}}
        placement="right"
        onClose={()=>setOpenDrawer(false)}
        open={openDrawer}
        key="right"
      >
        <Styled.MoreDrawerFirstDiv> <span style={{color: 'black'}}>Name : </span> <span style={{color: 'grey'}}> {selectedTableRow.customer_name ? selectedTableRow.customer_name : '-------------'} </span></Styled.MoreDrawerFirstDiv>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Mobile Number : </span>  <span style={{color: 'grey'}}>{selectedTableRow.customer_mobile_no ? selectedTableRow.customer_mobile_no : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Venue : </span>  <span style={{color: 'grey'}}>{selectedTableRow.venue ? selectedTableRow.venue : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Location : </span>  <span style={{color: 'grey'}}>{selectedTableRow.location ? selectedTableRow.location : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>City : </span>  <span style={{color: 'grey'}}>{selectedTableRow.city ? selectedTableRow.city : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Total Paid : </span>  <span style={{color: 'grey'}}>{selectedTableRow.amount ? selectedTableRow.amount : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Paid At : </span>  <span style={{color: 'grey'}}>{selectedTableRow.paid_at ? selectedTableRow.paid_at : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Paid For : </span>  <span style={{color: 'grey'}}>{selectedTableRow.paid_for ? selectedTableRow.paid_for : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Payment Mode : </span> <span style={{color: 'grey'}}>{selectedTableRow.payment_mode ? selectedTableRow.payment_mode : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Payment Source : </span> <span style={{color: 'grey'}}>{selectedTableRow.payment_source ? selectedTableRow.payment_source : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Transaction ID : </span> <span style={{color: 'grey'}}>{selectedTableRow.transaction_id ? selectedTableRow.transaction_id : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Package : </span>  <span style={{color: 'grey'}}>{selectedTableRow.subscription ? selectedTableRow.subscription.package : '-------------'}</span> </Styled.MoreDrawerContents>
        <Styled.MoreDrawerContents> <span style={{color: 'black'}}>Type : </span>  <span style={{color: 'grey'}}>{selectedTableRow.subscription ? selectedTableRow.subscription.subscription_type : '-------------'}</span> </Styled.MoreDrawerContents>

        <Button type="primary" style={{width: '100%', marginTop: '20px'}}><a href={selectedTableRow.invoice_link} target = "_blank" rel="noopener noreferrer"> View Invoice </a></Button>

      </Drawer>
      

      <Styled.DataStatsContainer>

        <Styled.DataStatsCard>

          <div style={{fontSize: 'small', color: 'grey'}}> Payment mode split up </div>
          
          {
              paymentModeSplitup.length > 0 ?
              
              
                            <Styled.PieDiv>
                              
                              <div style={{position: 'relative'}}>
                              <PieChart width={200} height={200}>
                                <Pie
                                  data={paymentModeSplitup}
                                  innerRadius={60}
                                  // outerRadius={80}
                                  fill="#8884d8"
                                  paddingAngle={5}
                                  dataKey="value"
                                >
                                  {paymentModeSplitup.map((entry, index) => (
                                    <Cell key={index} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                  ))}
                                </Pie>
                              </PieChart>
                              {
                                paymentModeSplitup &&
                                  <Styled.TotalAmount> {calcPaymentSum()} </Styled.TotalAmount>
                              }
                              </div>
                              
                              <div style={{display: 'flex', gap: '30px'}}>
                              <Styled.PieDivInfo>
                                {
                                  paymentModeSplitup.map((item,index)=>(
                                    index < 5 &&
                                    <Styled.InfoRow key={index}>
                                      <Styled.InfoColor style={{backgroundColor: COLORS[index]}} />
                                      <Styled.InfoLabel> {item.name} - {item.value? Math.round(item.value).toLocaleString('en-IN') : Math.round(item.value)}</Styled.InfoLabel>
                                    </Styled.InfoRow>
                                  ))
                                }
              
                              </Styled.PieDivInfo>

                              <Styled.PieDivInfo>
                                {
                                  paymentModeSplitup.map((item,index)=>(
                                    index >= 5 &&
                                    <Styled.InfoRow key={index}> 
                                      <Styled.InfoColor style={{backgroundColor: COLORS[index]}} />
                                      <Styled.InfoLabel> {item.name} - {item.value? Math.round(item.value).toLocaleString('en-IN') : Math.round(item.value)}</Styled.InfoLabel>
                                    </Styled.InfoRow>
                                  ))
                                }
              
                              </Styled.PieDivInfo>
                              </div>
              
                              </Styled.PieDiv>

              :
               <Empty style={{marginTop: '7%'}} description = {<span style={{color: 'grey', fontSize: 'small'}}> data not available </span>} />
            }

        </Styled.DataStatsCard>
        <span></span>

        <Styled.DataStatsCard>

          <div style={{fontSize: 'small', color: 'grey'}}> Payment source split up </div>

            {
              paymentSourceSplitup.map((item,index)=>(
                <div style={{marginTop: '5%'}} key={index}>
                  <div style={{marginTop: '5px', fontSize: '13px'}}>{item.payment__invoice__order__source ? item.payment__invoice__order__source : 'no source'} - {Math.round(item.total_amount).toLocaleString('en-IN')}</div>
                  <div style={{width: '100%', height: '8px', backgroundColor: 'whitesmoke', borderRadius: '3px'}}>
                    <div style={{width: ((Math.round(item.total_amount)/calcPaymentSourceSum())*100).toString()+'%', height: '8px', backgroundColor: COLORS[index], borderRadius: '3px'}}></div>  
                  </div>
                </div>

              ))
            }

            {
              paymentSourceSplitup.length == 0 &&
                <Empty style={{marginTop: '7%'}} description = {<span style={{color: 'grey', fontSize: 'small'}}> data not available </span>} />
            }
            

        </Styled.DataStatsCard>


      </Styled.DataStatsContainer>
      
      {
        paymentsWithoutTransID && notVerifiedCollection  &&
      
        <Styled.PaymentStatsDiv>

          <div>
            <div style={{display: 'flex', alignItems: 'center', gap: '15px'}}>
              {
                notVerifiedCollection.count > 0 ? 
                  <WarningTwoTone twoToneColor='rgb(246 173 50)' style={{fontSize: '25px'}} />
                :
                  <CheckCircleTwoTone twoToneColor='rgb(50 246 70)' style={{fontSize: '25px'}} />
              }
              <div>
                <div>{notVerifiedCollection.count > 0 ? 'Warning' : 'Completed'}</div>
                <div style={{fontSize: 'smaller', color: '#6f6f6f'}}>{notVerifiedCollection.count > 0 ? `${notVerifiedCollection.count} payments are not verified` : 'All payments are verified'}</div>
              </div>
              
              {notVerifiedCollection.count > 0 && <Button type="ghost" onClick={()=>applyCustomStatusFilter([['status', 'not verified']])} style={{marginLeft: '25px'}}>Show</Button>}

              {
                notVerifiedCollection.details.length > 0 && (
                  showUnverfiedCountList ?
                    <UpOutlined onClick={()=>setShowUnverfiedCountList(false)} style={{cursor: 'pointer'}} />
                  :
                    <DownOutlined onClick={()=>setShowUnverfiedCountList(true)} style={{cursor: 'pointer'}} /> )
              }
            </div>
            
            {

              showUnverfiedCountList &&
                <div style={{marginTop: '20px', maxHeight: '10vh', overflowY: 'scroll'}}>

                  {
                    notVerifiedCollection.details.map((item,itm_ind)=>(
                      <div key={itm_ind} style={{display: 'grid', gridTemplateColumns: '70% 5% 15%'}}>
                        <div style={{fontSize: 'smaller'}}>{item.venue__name}</div>
                        <div style={{textAlign: 'center'}}>-</div>
                        <div style={{fontSize: 'smaller', color: 'grey', textAlign: 'center'}}>{item.customer_count}</div>
                      </div>
                    ))
                  }
                  
                </div>
            }


        </div>

          <div style={{backgroundColor: 'whitesmoke', width: '2px', height: '50px'}}></div>
          <div>
            <div style={{display: 'flex', alignItems: 'center', gap: '15px'}}>
              {
                paymentsWithoutTransID.count > 0 ? 
                  <WarningTwoTone twoToneColor='rgb(246 173 50)' style={{fontSize: '25px'}} />
                :
                  <CheckCircleTwoTone twoToneColor='rgb(50 246 70)' style={{fontSize: '25px'}} />
              }
              <div>
                <div>{paymentsWithoutTransID.count > 0 ? 'Warning' : 'Completed'}</div>
                <div style={{fontSize: 'smaller', color: '#6f6f6f'}}>{paymentsWithoutTransID.count > 0 ? `Transaction ID of ${paymentsWithoutTransID.count} payments are missing` : 'Transaction ID of all payments are added'}</div>
              </div>
              
              {paymentsWithoutTransID.count > 0 && <Button type="ghost" onClick={()=>applyCustomStatusFilter([["status", "transaction id missing"]])} style={{marginLeft: '25px'}}>Show</Button>}

              {
                paymentsWithoutTransID.details.length > 0 && (
                  showIdMissingCountList ?
                    <UpOutlined onClick={()=>setShowIdMissingCountList(false)} style={{cursor: 'pointer'}} />
                  :
                    <DownOutlined onClick={()=>setShowIdMissingCountList(true)} style={{cursor: 'pointer'}} /> )
                }
            </div>

            {

              showIdMissingCountList &&
                <div style={{marginTop: '20px', maxHeight: '10vh', overflowY: 'scroll'}}>

                  {
                    paymentsWithoutTransID.details.map((item,itm_ind)=>(
                      <div key={itm_ind} style={{display: 'grid', gridTemplateColumns: '70% 5% 15%'}}>
                        <div style={{fontSize: 'smaller'}}>{item.venue__name}</div>
                        <div style={{textAlign: 'center'}}>-</div>
                        <div style={{fontSize: 'smaller', color: 'grey', textAlign: 'center'}}>{item.customer_count}</div>
                      </div>
                    ))
                  }
                  
                </div>
            }
            
          </div>

        </Styled.PaymentStatsDiv>
      }
      


      <div style = {{marginTop: '20px', backgroundColor: 'white', borderRadius: '10px', padding: '10px'}}>

        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
          

          <div style={{display: 'flex', gap: '10px', width: '100%'}}>
            <Cascader
              style={{
                width: '30%'
              }}
              value={appliedFilters}
              options={filterOptions}
              onChange={onChangeFilter}
              multiple
              disabled = {tableLoader} 
              // maxTagCount="responsive"
              showCheckedStrategy={SHOW_CHILD}
              showSearch={{
                filterCascader,
              }}
              onSearch={(value) => console.log(value)}
              
              placeholder="Filter by"
            />
            <Button type="primary" onClick={applyFilters} disabled = {tableLoader}>Apply</Button>
          </div>


          <Search disabled = {tableLoader} allowClear placeholder="enter phone number" enterButton style={{width: '30%'}} loading={false} value = {searchCustomer} onSearch={onSearchCustomer} onChange={(e)=>onChangeNumberSearch(e.target.value)} />
        </div>

        <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto', alignItems: 'center', marginTop: '20px'}}>
          <div>
            <span style={{fontSize: 'small', color: 'grey',}}>Sort by Paid At &nbsp;&nbsp;&nbsp;&nbsp;</span>
            <Segmented disabled = {tableLoader} options={['oldest first', 'newest first']} value={sortSelector} onChange={setSortSelector} style={{fontSize: 'small'}} />
          </div>
          
          <Popover placement="top" content={popoverExcludeContent} title={popoverExcludeTitle}>
            <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', width: 'fit-content'}}>
                <div style={{fontSize: 'small', color: 'grey'}}>( showing {totalEntries ? totalEntries.toLocaleString('en-IN') : 0} entries )</div>
                <InfoCircleOutlined style={{marginLeft: '5px', color: 'grey'}} />
            </div>
          </Popover>

          <div style={{fontSize: 'small', color: 'green', textAlign: 'right'}}>Total : {totalPaidSum ? totalPaidSum.toLocaleString('en-IN') : 0}</div>
        </div>

        <div style={{marginTop: '20px', display: 'flex', justifyContent: 'flex-end'}}>
          { 
            pendingCollectionToVerify > 0 &&
              <Popconfirm
                title="How do you wish to verify these collections?"
                onConfirm={verifyAll}
                onCancel={onVerifyWithTrans}
                okText="Verify all"
                cancelText="Verify by entering a common tranaction id"
              >
                <Button disabled = {tableLoader} loading={verifyAllLoader}  type="primary">Verify pending {pendingCollectionToVerify} entries</Button>
              </Popconfirm>
          }
        </div>

        <Table className = 'collection-custom-table' columns={columns} dataSource={collectionData} style={{marginTop: '20px'}} scroll={{ x: true }} loading={tableLoader} onChange={onChangePage} pagination={{ current: tablePage, total: totalEntries, pageSize: tablePageSize, showSizeChanger: false }} />

      </div>



    </Styled.MainContainer>
  );
}

export default Collection;