import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import styled from 'styled-components'

import Login from './pages/login'
import NotFound from './pages/pageNotFound'
import SideBar from './components/sideBar'
import AddContract from './pages/contract/addContract'
import ContractInfo from './pages/contract/contract'
import Overview from './pages/overview'
import Revenue from './pages/revenue'
import Collection from './pages/collection'
import AddExpense from './pages/expense'
import AddBuddyData from './pages/buddyData'
import SalaryDetails from './pages/salaryDetails'
import BuddyDetails from './pages/buddyInfo'
import ExpenseDetails from './pages/expenseDetail'
import ContractTermAddition from './pages/contract/contractTerm'
import Dashboard from './pages/dashboard'
import Batches from './pages/batches'
import Leads from './pages/leads'
import Reports from './pages/reports'
import CollectionList from './pages/collectionList'
import BatchDetails from './pages/batchDetails'
import RevenueDetails from './pages/customerList/revenueDetails'
import CollectionDetails from './pages/customerList/collectionDetails'
import LeadList from './pages/customerList/leadList'
import Customers from './pages/customers'
import CustomerSummary from './pages/customers/customerSummary'
import CustomerList from './pages/customerList/customerList'
import CollectionSummary from './pages/collectionList/collectionSummary'
import LeadsProcessed from './pages/leads/leadsProcessed'
import LeadsDetails from './pages/leads/leadsList'

function App() {
  const isUserLoggedIn = !!localStorage.getItem('access_id');

  return (
    <Router>
      <MainContainer>
        {isUserLoggedIn ? (
          <>
            <SideBar />
            <ContainerBody>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/revenue" element={<Revenue />} />
                <Route path="/collection" element={<CollectionList />} />
                <Route path="/addContract" element={<AddContract />} />
                <Route path="/contractInfo" element={<ContractInfo />} />
                <Route path="/addExpense" element={<AddExpense />} />
                <Route path="/addcontractterms" element={<ContractTermAddition />} />
                <Route path="/salaryDetails" element={<SalaryDetails />} />
                <Route path="/buddyData" element={<AddBuddyData />} />
                <Route path="/buddyinfo" element={<BuddyDetails />} />
                <Route path="/expenseDetails" element={<ExpenseDetails />} />
                <Route path="/batches" element={<Batches />} />
                <Route path="/leads" element={<Leads />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/batchDetails/:venue_id" element={<BatchDetails />} />
                <Route path="/revenueList/:venue_id" element={<RevenueDetails />} />
                <Route path="/collectionList/:venue_id" element={<CollectionDetails />} />
                <Route path="/leadList/:venue_id" element={<LeadList />} />
                <Route path="/customers/:venue_id" element={<CustomerSummary />} />
                <Route path="/collection/:venue_id" element={<CollectionSummary />} />
                <Route path="/customerList" element={<CustomerList/>} />
                <Route path="/leadsProcessed/" element={<LeadsProcessed/>} />
                <Route path="/leadDetails/" element={<LeadsDetails/>} />
                {/* NotFound route moved outside the conditional rendering */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </ContainerBody>
          </>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </MainContainer>
    </Router>
  );
}

export default App;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ContainerBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #F0F2F5;
`;
