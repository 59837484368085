import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 20px;
  width: 85vw;
`;

export const TopDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SelectorContainer = styled.div`
	display: flex;
`;

export const Selector = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display:flex;
  justify-content : ${props=>props.active ? 'space-between':'center'};
  font-size: large;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 5px;
`

export const StatBox = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  width: 27vw;
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f0fffe;
  }
`

export const IconDiv = styled.div`
  display: flex;
  justify-content: center;
`

export const LabelDiv = styled.div`
  display: flex;
  flex-direction:column;
`

export const StatNumber = styled.div`
  font-size: large;
  color: black;
`

export const StatLabel = styled.div`
  font-size: 14px;
  color: grey;
`
