import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 20px;
  width: 85vw;
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display:flex;
  justify-content : space-between;
  font-size: large;
  margin-top: 10px;
  margin-bottom: 10px;
`;
