import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,useParams} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input, Tooltip } from 'antd';
import { ArrowRightOutlined,StarFilled} from '@ant-design/icons';
import { FaAngleDoubleLeft } from "react-icons/fa";

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const { Option } = Select;
const { TextArea } = Input;

const LeadList = () => {
  const navigate = useNavigate();
  const { venue_id } = useParams();
  var local_selectedVenueId = venue_id
  var local_month = JSON.parse(localStorage.getItem('month'));
  var local_year = JSON.parse(localStorage.getItem('year'));

  const [loading, setLoading] = useState(true)
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [leadData, setLeadData] = useState([])
  const [venueName, setVenueName] = useState('')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(25)

  useEffect(() => {
    setLoading(true)
    getLeadDetails()
  }, [selectedVenueId, page]); 

  const getLeadDetails = () => {
    var date = local_year + '-' + local_month
    getData(`/sales/find_leads/?page=${page}&source=CM&sortColumn=status,follow_up_date&sortOrder=false&venue_id=${selectedVenueId}`)
    .then(response =>{
      setLeadData(response.results)
      setTotalPages(response.num_pages)
      if(response.results[0]){
        setVenueName(response.results[0].venue)
      }
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const handlePageChange = (pagination) => {
    setPage(pagination.current);
  };

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile_no_hidden',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value) => {
        return(
          <div>
          {
            value == '10' ?
              <div className='statusColumn' style={{backgroundColor:'#3cb371'}}>
                Response
              </div>
            :
            value == '20' ?
              <div className='statusColumn' style={{backgroundColor:'#bdb76b'}}>
                Contact Later
              </div>
            :
            value == '30' ?
              <div className='statusColumn' style={{backgroundColor:'#cd853f'}}>
                Demo Scheduled
              </div>
            :
            value == '40' ?
              <div className='statusColumn' style={{backgroundColor:'#f08080'}}>
                Did not Demo
              </div>
            :
            value == '50' ?
              <div className='statusColumn' style={{backgroundColor:'#4682b4'}}>
                Verbal Confirmation
              </div>
            :
            value == '90' ?
              <div className='statusColumn' style={{backgroundColor:'#778899'}}>
                Dormant
              </div>
            :
            value == '100' ?
              <div className='statusColumn' style={{backgroundColor:'#cd5c5c'}}>
                Injured
              </div>
            :
            value == '120' ?
              <div className='statusColumn' style={{backgroundColor:'#66cdaa'}}>
                Demo Attended
              </div>
            :
            value == '130' ?             
              <div className='statusColumn' style={{backgroundColor:'#b8860b'}}>
                Temporarily Inactive
              </div>
            :null
          }
          </div>
          )
      },
    },
    {
      title: 'Follow Up-Date',
      dataIndex: 'follow_up_date',
      render: (value) => {
        return moment(value).format('MMM DD,YYYY')
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
    },
    {
      title: 'Quality',
      dataIndex: 'quality',
      render: (value) => {
        return(
          <div>
          {
            value == 'Sure Payment' ?
              <div style={{display:'flex',flexDirection:'row'}}>
                <StarFilled style={{color: 'orange'}}/>
                <StarFilled style={{color: 'orange'}}/>
                <StarFilled style={{color: 'orange'}}/>
                <StarFilled style={{color: 'orange'}}/>
                <StarFilled style={{color: 'orange'}}/>
              </div>
            :
            value == 'Highly Likely' ?
              <div style={{display:'flex',flexDirection:'row'}}>
                <StarFilled style={{color: 'orange'}}/>
                <StarFilled style={{color: 'orange'}}/>
                <StarFilled style={{color: 'orange'}}/>
                <StarFilled style={{color: 'orange'}}/>
              </div>
            :
            value == 'May Pay' ?
              <div style={{display:'flex',flexDirection:'row'}}>
                <StarFilled style={{color: 'orange'}}/>
                <StarFilled style={{color: 'orange'}}/>
                <StarFilled style={{color: 'orange'}}/>
              </div>
            :
            value == 'No Response' ?
              <div style={{display:'flex',flexDirection:'row'}}>
                <StarFilled style={{color: 'orange'}}/>
                <StarFilled style={{color: 'orange'}}/>
              </div>
            :
            value == 'Will Not Pay' && 
              <div style={{display:'flex',flexDirection:'row'}}>
                <StarFilled style={{color: 'orange'}}/>
              </div>
          }
          </div>
          )
      },
    },
    {
      title: 'Source',
      dataIndex: 'source',
    },
  ]

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.Header >
          <Tooltip title="Back"><a style={{cursor: 'pointer',color:'#02475c'}} onClick={()=>navigate('/leads')}><FaAngleDoubleLeft/> </a></Tooltip>
           <a>Hot Leads - {venueName}</a>
           <a></a>
        </Styled.Header>

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={leadData}
          pagination={{
            current: page,
            total: totalPages * 25,
            pageSize: 25,
            showQuickJumper: true,
            showSizeChanger: false,
          }}
          onChange={handlePageChange}
         />

      </Styled.MainContainer>

    </Spin>
  );
}

export default LeadList;