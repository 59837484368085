import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 20px;
`;

export const TopDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SelectorContainer = styled.div`
	display: flex;
`;

export const Selector = styled.div`
	display: flex;
  flex-direction: column; 
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display:flex;
  justify-content : center;
  font-size: large;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const GraphContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 2% 38%;
  height: 40vh;
`;

export const GraphDiv = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 5px;  
  margin-bottom: 10px;
`;

export const GraphDivLabelDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const LabelHeading = styled.div`
  font-size: 15px;
  color: #02475c;
`;

export const GraphMonthSelector = styled.div`
  padding: 7px;
  border-radius: 10px;
  border: 1px solid whitesmoke;
  font-size: 12px;
  color: grey;
`;

export const StatsDiv = styled.div`
  margin-bottom: 5px;
  padding: 2px;
  display: grid;
  grid-template-columns: 24% 1% 24% 1% 24% 1% 24% 1%;
`;

export const StatsBox = styled.div` 
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  border: 2px solid #d8f1f2;
  display: flex;
  justify-content: center;
}
`;

export const StatsLabel = styled.div` 
  font-size: 14px;
  font-weight: 600;
  padding-right: 3px;
}
`;

export const PieDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PieDivInfo = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const InfoColor = styled.span`
  width: 15px;
  height: 15px;
  margin-right: 10px;
`;

export const InfoLabel = styled.div`
  font-size: 13px;
`;

export const TotalAmount = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
