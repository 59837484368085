import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,useParams} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { FaAngleDoubleLeft } from "react-icons/fa";

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;

const BatchDetails = () => {
  const navigate = useNavigate();
  const { venue_id } = useParams();
  var local_selectedVenueId = venue_id

  var local_game_type = JSON.parse(localStorage.getItem('gameType'))

  const [loading, setLoading] = useState(true)
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [batchesData, setBatchesData] = useState([])
  const [venueName, setVenueName] = useState('')
  const [gameType, setGameType] = useState(local_game_type)

  useEffect(() => {
    setLoading(true)
    getBatchDetails()
  }, [selectedVenueId]); 

  const getBatchDetails = () => {
    getData(`/business/get_batches_overview/?venue_id=${selectedVenueId}&game_type_id=${gameType}`)
    .then(response =>{
      setBatchesData(response.result)
      if(response.result[0]){
        setVenueName(response.result[0].venue)
      }
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const rowClassName = (record) => {
    if (record.utilization >= 80) {
      return 'high-utilization-row';  
    } else if (record.utilization > 40) {
      return 'medium-utilization-row';
    } else {
      return 'low-utilization-row';
    }
  };

  const columns = [
    {
      title: 'Batch',    
      render: (_, record) => {
      const { start_time, end_time, days, multi_timings, id } = record;

      return (
        <div>
          <div>
            {
              (multi_timings && multi_timings.length > 0) ?
              <div>
                {
                  multi_timings.map(x=>(
                    <div style={{display:'flex'}}>
                      <span style={{flex:1}}>{x.day + ' : '}</span>
                      <span style={{flex:4}}>{x.start_time + ' - ' + x.end_time}</span>
                    </div>
                    ))
                }
              </div>
            :
              <div>
                <div>{days}</div>
                <div>{start_time + ' - ' + end_time}</div>
              </div>
            }
          </div>
        </div>
      );
    },
    },
    {
      title: 'Coach',
      dataIndex: 'buddy_names',
      render: (value) => {
        return(
          <div>
            {
              value.map(x=>(
                <div>{x}</div>
                ))
            }
          </div>
          )
      },

    },
    {
      title: 'Age Group',
      dataIndex: 'age_group_str',
      sorter: (a, b) => a.age_group_str.localeCompare(b.age_group_str),
    },
    {
      title: 'Skill Level',
      dataIndex: 'game_level_str',
      sorter: (a, b) => a.game_level_str.localeCompare(b.game_level_str),
    },
    {
      title: 'Capacity',
      dataIndex: 'batch_size',
      sorter: (a, b) => a.batch_size - b.batch_size
    },
    {
      title: 'Active',
      dataIndex: 'active_count',
      sorter: (a, b) => a.active_count - b.active_count
    },
    {
      title: 'Payment Due',
      dataIndex: 'paymentdue_count',
      sorter: (a, b) => a.paymentdue_count - b.paymentdue_count
    },
    {
      title: 'Utilization',
      dataIndex: 'utilization',
      render: (value) => {
        return(
          <span style={{padding:'5px',borderRadius:'5px',fontWeight:600,backgroundColor: value >= 80 ? '#38ca38' : (value < 80 && value > 40) ? '#f7b08d' : '#f79292'}} >
            {value + '%'}                
          </span>
          )
      },
      sorter: (a, b) => a.utilization - b.utilization
    },
    {
      title: '',
      dataIndex: 'id',
      render: (value) => {
        return(
          <Styled.Icon target="_blank" href={`https://cm.sporthood.in/#/fixtures/${value}`}>
            <ArrowRightOutlined/>
          </Styled.Icon>
          )
      }
    },
  ]

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.Header >
          <Tooltip title="Back"><a style={{cursor: 'pointer',color:'#02475c'}} onClick={()=>navigate('/batches')}><FaAngleDoubleLeft/></a></Tooltip>
           <a>Batch Overview - {venueName} - {gameType == 8 ? 'Football Academy' : gameType == 10 ? 'Badminton Academy' :gameType == 65 ? 'Swimming Academy' : null}</a>
           <a></a><a></a>
        </Styled.Header>

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={batchesData}
          pagination={{ pageSize: 20, showSizeChanger: false}}
         />

      </Styled.MainContainer>
    </Spin>
  );
}

export default BatchDetails;