import React, {useState, useEffect, useRef} from "react";
import {Routes, Route, useNavigate, Link} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input } from 'antd';
import { ArrowRightOutlined, ClockCircleOutlined, CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;

const Lead = () => {
  var today = new Date();
  var local_month = JSON.parse(localStorage.getItem('month'));
  if(local_month){
   var mm = local_month
  }else{
      var mm = String(today.getMonth() + 1).padStart(2, '0')
  }

  var yyyy = today.getFullYear()
  yyyy = yyyy.toString()

  var local_city = JSON.parse(localStorage.getItem('cityId'))
  var local_selectedVenueId = JSON.parse(localStorage.getItem('selectedVenueId'))
  if(!local_selectedVenueId){
    local_selectedVenueId = 'All'
  }

  const [loading, setLoading] = useState(true)
  const [venues, setVenues] = useState([])
  const [cities, setCities] = useState([])
  const [cityId, setCityId] = useState(local_city)
  const [venuelist, setVenuelist] = useState([])
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [leadData, setLeadData] = useState([])
  const [month, setMonth] = useState(mm)
  const [year, setYear] = useState(yyyy)
  const [leadSummary, setLeadSummary] = useState([])
  const [header, setHeader] = useState([])
  const [heading, setHeading] = useState([])
  const [leadDetails, setLeadDetails] = useState([])
  const [hotLead, setHotLead] = useState('')
  const [leadGenerated, setLeadGenerated] = useState('')
  const [leadProcessed, setLeadProcessed] = useState('')

  useEffect(() => {
    localStorage.setItem('selectedVenueId',JSON.stringify(selectedVenueId))
    localStorage.setItem('cityId',JSON.stringify(cityId))
    localStorage.setItem('month', JSON.stringify(month));
    localStorage.setItem('year', JSON.stringify(year));
  }, [selectedVenueId,cityId,month,year]);

  useEffect(() => {
     getVenues()
  }, []); 

  useEffect(() => {
    if(cityId !== ''){
      getLeadDetails()
    }
  }, [selectedVenueId,cityId]); 

  useEffect(() => {
    if(cityId !== ''){
      setLoading(true)
      Promise.all([getLeadsGenerated(), getLeadsProcessed(), getLeadStats()])
      .then(() => {
        setLoading(false); 
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    }
  }, [month,year,selectedVenueId,cityId]);

  useEffect(() => {
    getVenuesInCity()
  }, [cities,cityId]);

  const getVenuesInCity =() =>{
    if(cities[0] && !cityId){
      setCityId(cities[0].id)
    }
    if(cityId == 'All'){
      setVenuelist(venues)
    }
    else{
      setVenuelist( 
        venues.filter((item)=>{
              return item.city === cityId
            })
      ) 
    }
    setSelectedVenueId('All')
  }

  const getVenues = async () => {
    await getData('/business/get_venues_for_buddies/')
    .then(response =>{
      setVenues(response.result)
      setCities(response.cities)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const getLeadDetails = async () => {
    await getData(`/business/get_hot_leads_overview/?venue_id=${selectedVenueId}&city_id=${cityId}`)
    .then(response =>{
      setLeadData(response.result)
    })
    .catch(error => {
      alert(error.message);
    });
  }

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  }; 

  const getLeadsGenerated = async () => {
    var venue = ''
    if(selectedVenueId == 'All'){
      if(cityId){
        var venue = cityId
      }
    }
    else{
      var venue = selectedVenueId
    }
    if(venue == 'All'){
      var venue = 0
    }

    var date = year + '-' + month + '-01'

    await getData(`/eagleeye/getTableDataJSON/?report_id=750760&date=${date}&venue_id=${venue}`)
    .then(response =>{
      setLeadSummary(response.data)
      setHeader(response.headers)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const lead_columns = header.filter(col => col !== 'venue_id').map(col => ({
    title: col.charAt(0).toUpperCase() + col.slice(1).replace(/_/g, ' '),
    dataIndex: col,
    key: col,
    sorter: col === 'total' ? (a, b) => a.total - b.total : false,
    align: col !== 'venue' && 'center',
    render: col === 'venue' ? 
          (text, record) => {
            var date = year + '-' + month + '-01';
            const venuename = text.length > 35 ? `${text.slice(0, 35)}...` : text;
            return(
              <Link to={`/leadDetails/?venue_id=${record.venue_id}&report_no=76379&date=${date}`} className='venueTitle'>
                {venuename}
              </Link>
            )
          }
        : 
        text => text
        
  }))
  .concat([
    {
      title: '',
      dataIndex: 'venue_id',
      key: 'action',
      render: (value) => {
        var date = year + '-' + month + '-01';
        return(
          <Link to={`/leadDetails/?venue_id=${value}&report_no=76379&date=${date}`} className="venueTitle">
            <ArrowRightOutlined />
          </Link>
        )
      },
      align: 'center'
    }
  ]);

  const getLeadsProcessed = async () => {
    var venue = ''
    if(selectedVenueId == 'All'){
      if(cityId){
        var venue = cityId
      }
    }
    else{
      var venue = selectedVenueId
    }

    if(venue == 'All'){
      var venue = 0
    }

    var date = year + '-' + month + '-01'

    await getData(`/eagleeye/getTableDataJSON/?report_id=750761&venue_id=${venue}&date=${date}`)
    .then(response =>{
      setHeading(response.headers)
      setLeadDetails(response.data)   
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const getLeadStats = async () => {
    var venue = ''
    if(selectedVenueId == 'All'){
      if(cityId){
        var venue = cityId
      }
    }
    else{
      var venue = selectedVenueId
    }

    if(venue == 'All'){
      var venue = 0
    }

    var date = year + '-' + month + '-01'

    await getData(`/eagleeye/getTableDataJSON/?report_id=76378&venue_id=${venue}&date=${date}`)
    .then(response =>{
      if(response.data[0]){
        setHotLead(response.data[0].hot_leads)
        setLeadProcessed(response.data[0].lead_processed)
        setLeadGenerated(response.data[0].lead_generated)
      }  
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const lead_processed = heading.filter(col => col !== 'venue_id').map(col => ({
    title: col.charAt(0).toUpperCase() + col.slice(1).replace(/_/g, ' '),
    dataIndex: col,
    key: col,
    sorter: col === 'total' ? (a, b) => a.total - b.total : false,
    align: col !== 'venue' && 'center',
    render: col === 'venue' ? 
          (text, record) => (
            <Link to={`/leadsProcessed/?venue_id=${record.venue_id}`} className='venueTitle'>
              {record.venue}
            </Link>
          )
        : 
        text => text
        
  }))
  .concat([
    {
      title: '',
      dataIndex: 'venue_id',
      key: 'action',
      render: (value) => (
        <Link to={`/leadsProcessed/?venue_id=${value}`} className="venueTitle">
          <ArrowRightOutlined />
        </Link>
      ),
      align: 'center'
    }
  ]);

  const columns = leadData.length > 0 ? 
    [
      ...Object.keys(leadData[0]).filter(key => key !== 'venue_id').map(key => ({
        title: key == 'verbal_confirm' ? 'Verbal Confirmation' : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),  
        dataIndex: key,
        key: key,
        align: key !== 'venue' && 'center',
        sorter: key !== 'venue' ? (a, b) => a[key] - b[key] : false,
        render: key === 'venue' ? 
                  (text, record) => (
                    <Link to={`/leadList/${record.venue_id}`} className='venueTitle'>
                      {record.venue}
                    </Link>
                  )
                : 
                text => text
        })),
      {
        title: '',
        dataIndex: 'venue_id',
        render: (value) => {
          return(
            <Link to={`/leadList/${value}`} className='venueTitle'>
              <ArrowRightOutlined/>
            </Link>
            )
        }
      }
    ]
  : 
    [];

  const hotLeads = useRef(null);
  const leadsGenerated = useRef(null);
  const leadsProcessed = useRef(null);

  const handleClick = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.TopDiv>    
          <Styled.SelectorContainer>

            {cities.length > 1 &&         
              <Styled.Selector>
                <span className='indicatorText'>City</span>
                <Select
                  className = 'mainSelector'
                  style={{width: 150}}
                  showSearch = {true}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                  onChange={setCityId}
                  value = {cityId}
                  bordered={false}
                >
                  <Option value="All" name="All"> All </Option>
                  {
                    cities.map(item=>(
                      <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                    ))
                  }
                </Select>
              </Styled.Selector>
            }

            <Styled.Selector> 
              <span className='indicatorText'>Venue</span>
              <Select
                className = 'mainSelector'
                style={{width: 300}}
                showSearch = {true}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={setSelectedVenueId}
                value = {selectedVenueId}
                bordered={false}
              >
                <Option value="All" name="All"> All </Option>
                {venuelist.map(item=>(
                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                  ))
                }
              </Select>
            </Styled.Selector>
          </Styled.SelectorContainer>

          <div style={{display: 'flex'}}>
            <Styled.Selector>
              <span className='indicatorText'>Month</span>
              <Select
                  className = 'mainSelector'
                  style={{width: 150}}
                  onChange={setMonth}
                  value = {month}
                  bordered={false}
                >
                  <Option value="12"> December </Option>
                  <Option value="11"> November </Option>
                  <Option value="10"> October </Option>
                  <Option value="09"> September </Option>
                  <Option value="08"> August </Option>
                  <Option value="07"> July </Option>
                  <Option value="06"> June </Option>
                  <Option value="05"> May </Option>
                  <Option value="04"> April </Option>
                  <Option value="03"> March </Option>
                  <Option value="02"> February </Option>
                  <Option value="01"> January </Option>
                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <span className='indicatorText'>Year</span>
              <div className = 'mainSelector'> 
                <DatePicker defaultValue={moment(year, 'YYYY')}  onChange = {onChangeYear}  bordered={false}  picker="year" />
              </div>
            </Styled.Selector>
          </div>

        </Styled.TopDiv>

        <Styled.StatContainer>
          <Styled.StatBox onClick={() => handleClick(hotLeads)}>
            <Styled.IconDiv>
              <ClockCircleOutlined style={{fontSize: 'xx-large',color:'#4682b4'}} />
            </Styled.IconDiv>
            
            <Styled.LabelDiv>
              <Styled.StatLabel> Hot Leads Today </Styled.StatLabel>
              <Styled.StatNumber>{hotLead}</Styled.StatNumber>
            </Styled.LabelDiv>
          </Styled.StatBox>

          <Styled.StatBox onClick={() => handleClick(leadsProcessed)}>
            <Styled.IconDiv>
              <CheckCircleOutlined style={{fontSize: 'xx-large',color:'#04BFB9'}} />
            </Styled.IconDiv>
            
            <Styled.LabelDiv>
              <Styled.StatLabel> Leads Processed - {moment(month, 'MM').format('MMM') + ' ' + year}</Styled.StatLabel>
              <Styled.StatNumber>{leadProcessed}</Styled.StatNumber>
            </Styled.LabelDiv>
          </Styled.StatBox>

          <Styled.StatBox onClick={() => handleClick(leadsGenerated)}>
            <Styled.IconDiv>
              <PlusCircleOutlined style={{fontSize: 'xx-large',color:'#02475c'}} />
            </Styled.IconDiv>
            
            <Styled.LabelDiv>
              <Styled.StatLabel> Leads Generated - {moment(month, 'MM').format('MMM') + ' ' + year}</Styled.StatLabel>
              <Styled.StatNumber>{leadGenerated}</Styled.StatNumber>
            </Styled.LabelDiv>
          </Styled.StatBox>
        </Styled.StatContainer>

      <div ref={hotLeads}>
        <Styled.Header>          
          <span>Hot Leads Overview - Today</span>
        </Styled.Header>

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={leadData}
          pagination={{ pageSize: 10, showSizeChanger: false}}
         />
      </div>

      <div ref={leadsProcessed}>
        <Styled.Header>          
          <span>Leads Processed - {moment(month, 'MM').format('MMMM') + ' ' + year}</span>
        </Styled.Header>

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={lead_processed}
          dataSource={leadDetails.map((row, index) => ({
                          ...row,
                          key: index,  
                        }))
                      }
          pagination={{ pageSize: 100, showSizeChanger: false}}
         />
      </div>

      <div ref={leadsGenerated}>
        <Styled.Header>          
          <span>Leads Generated - {moment(month, 'MM').format('MMMM') + ' ' + year}</span>
        </Styled.Header>

        <Table
          className="ant-table-thead table_container"
          rowKey="id"
          columns={lead_columns}
          dataSource={leadSummary.map((row, index) => ({
                          ...row,
                          key: index,  
                        }))
                      }
          pagination={{ pageSize: 10, showSizeChanger: false}}
         />
      </div>

      </Styled.MainContainer>
    </Spin>
  );
}

export default Lead;