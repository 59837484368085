import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display:flex;
  justify-content : center;
  font-size: large;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const MainDiv = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  margin: 15px;
  justify-content: space-between;
`

export const CategoryDiv = styled.div`
  background: white;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const CategoryHeading = styled.div`
  padding: 5px;
  font-size: medium;
  font-weight: 900;
`

export const ReportDiv = styled.div`
  width: 100%;
  padding: 5px;
  text-align: left;
  margin-left: 50px;
`

export const ReportName = styled.li`
  font-size: medium;
  margin-bottom: 10px;

  &:hover {
    color: #047294;
    cursor: pointer;
    transition: 0.5s; 
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
  width: 100%;
`

export const Form = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
`

export const FormHeading = styled.div`
  background-color: #047294;
  color: white;
  font-size: large;
  font-weight: 900;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  margin-bottom: 15px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`

export const FormOption = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3vh;
`

export const Option = styled.div`
  font-weight: 500;
`