import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,useParams} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { FaAngleDoubleLeft } from "react-icons/fa";

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const { Option } = Select;
const { TextArea } = Input;

const CustomerList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const venueId = queryParams.get('venue_id');
  const type = queryParams.get('type');
  var local_month = JSON.parse(localStorage.getItem('month'));
  var local_year = JSON.parse(localStorage.getItem('year'));
  var local_game_type = JSON.parse(localStorage.getItem('gameType'))

  const [loading, setLoading] = useState(false)
  const [selectedVenueId, setSelectedVenueId] = useState(venueId)
  const [selectedGameType, setSelectedGameType] = useState(local_game_type)
  const [customerData, setCustomerData] = useState([])
  const [header, setHeader] = useState([])
  const [venueName, setVenueName] = useState('')

  useEffect(() => {
    setLoading(true)
    getCustomerList()
  }, [selectedVenueId]); 

  const getCustomerList = () => {
    var report_no = 76373
    if(type == 'renewed'){
      report_no = 76374
    }
    else if(type == 'new'){
      report_no = 76375
    }
    else if(type == 'expired'){
      report_no = 76376
    }
    var date = local_year + '-' + local_month + '-01'
    getData(`/eagleeye/getTableDataJSON/?report_id=${report_no}&venue_id=${selectedVenueId}&date=${date}&game_type_id=${selectedGameType}`)
    .then(response =>{
      setCustomerData(response.data)
      setHeader(response.headers)
      if(response.data[0]){
        setVenueName(response.data[0].Venue)
      }
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const columns = header.map(col => ({
    title: col.charAt(0).toUpperCase() + col.slice(1).replace(/_/g, ' '),
    dataIndex: col,
    key: col,
    align: 'center',
    width: 130,
    render: (text) => {
      if (col === 'Batch') {
        return (
          <div           
            dangerouslySetInnerHTML={{ __html: text.split(',').join('<br />') }}
          />
        );
      }
      return text.length > 35 ? `${text.slice(0, 35)}...` : text;
    }
  }));

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.Header >
          <Tooltip title="Back"><a style={{cursor: 'pointer',color:'#02475c'}} onClick={()=>navigate('/customers')}><FaAngleDoubleLeft/></a></Tooltip>
           {venueName && 
             <>
              <a>{venueName} - {type ? type.charAt(0).toUpperCase() + type.slice(1) : 'Active'} Customers - {moment(local_month, 'MM').format('MMMM') + ' ' + local_year}</a>
              <ReactHTMLTableToExcel
                className="btn btn-sm downloadBtn"
                table="table-to-download"
                filename={type ? type.charAt(0).toUpperCase() + type.slice(1) + ' Customers ' + venueName : 'Active Customers ' + venueName}
                sheet={type ? type.charAt(0).toUpperCase() + type.slice(1) + ' Customers' : 'Active Customers'}
                buttonText="Download"/>
             </>
          }
        </Styled.Header>
        <div className="recordCount">{customerData.length} records</div>

        <Table
          className="ant-table-thead table_container"
          tableLayout="fixed"
          rowKey="id"
          columns={columns}
          dataSource={customerData}
          pagination={{ pageSize: 100, showSizeChanger: false}}
         />


      </Styled.MainContainer>

      <table style ={{display: 'none'}} id="table-to-download">
        <thead>
          <tr>
            {
              header.map((head)=>(
                <th>{head.charAt(0).toUpperCase() + head.slice(1).replace(/_/g, ' ')}</th>
                )
              )
            }
          </tr>
        </thead>
        <tbody>
            {customerData.map((row, rowIndex) => (
              <tr key={rowIndex}> 
                {Object.values(row).map((value, colIndex) => (
                  <td key={colIndex}>{value}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>

    </Spin>
  );
}

export default CustomerList;