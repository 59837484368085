import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { message, Popconfirm } from 'antd';
// import { DollarCircleOutlined, LogoutOutlined, UserOutlined, FileAddOutlined, FileSearchOutlined, DashboardOutlined, DatabaseOutlined, SolutionOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { DollarCircleOutlined, LogoutOutlined, UserOutlined, AppstoreFilled, AppstoreOutlined, BankFilled, BankOutlined, DatabaseFilled, DatabaseOutlined, ContactsFilled, ContactsOutlined, DollarTwoTone, FilterFilled, FilterOutlined } from '@ant-design/icons';
import * as Styled from './style.js';
import logo from '../../assets/images/sp-logo-white.png';

function SideBar() {
  const [active, setActive] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.setItem('access_id', '');
    localStorage.setItem('user_role', '');
    localStorage.setItem('user_name', '');
    navigate('/');
    window.location.reload();
  };

  return (
    <Styled.Sidebar>
      <Styled.SidebarContainer>
        <Styled.SidebarLogoContainer>
          <Styled.Logo src={logo} alt="logo" />
          <span style={{color:'white',fontWeight:'900'}}>Business Portal</span>
        </Styled.SidebarLogoContainer>

        <Styled.SidebarContainer>
          <Styled.SidebarMenuContainer>
            <Styled.SidebarItems>
              <Link to="/">
                <Styled.MenuContainer active={location.pathname === '/'}>
                  {location.pathname === '/' ?
                    <AppstoreFilled style={{color: 'white'}} /> 
                    : 
                    <AppstoreOutlined style={{color: '#047294'}} />
                  }
                  <Styled.MenuLabel active={location.pathname === '/'}>Dashboard</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>

              <Link to="/customers">
                <Styled.MenuContainer active={location.pathname === '/customers'}>
                  <UserOutlined style={{color: (location.pathname === '/customers' ? 'white': '#047294')}}/>
                  <Styled.MenuLabel active={location.pathname === '/customers'}>Customers</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>
              
              <Link to="/revenue">
                <Styled.MenuContainer active={location.pathname === '/revenue'}>
                  <DollarCircleOutlined style={{color: (location.pathname === '/revenue' ? 'white': '#047294')}}/>
                  <Styled.MenuLabel active={location.pathname === '/revenue'}>Revenue</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>

              <Link to="/collection">
                <Styled.MenuContainer active={location.pathname === '/collection'}>
                  {location.pathname === '/collection' ?
                    <BankFilled style={{color: 'white'}} /> 
                    : 
                    <BankOutlined style={{color: '#047294'}} />
                  }
                  <Styled.MenuLabel active={location.pathname === '/collection'}>Collection</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>
              
              <Link to="/batches">
                <Styled.MenuContainer active={location.pathname === '/batches'}>
                  {location.pathname === '/batches' ?
                    <ContactsFilled style={{color: 'white'}} /> 
                    : 
                    <ContactsOutlined style={{color: '#047294'}} />
                  }
                  <Styled.MenuLabel active={location.pathname === '/batches'}>Batches</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>
              
              <Link to="/leads">
                <Styled.MenuContainer active={location.pathname === '/leads'}>
                  {location.pathname === '/leads' ?
                    <DatabaseFilled style={{color: 'white'}} /> 
                    : 
                    <DatabaseOutlined style={{color: '#047294'}} />
                  }
                  <Styled.MenuLabel active={location.pathname === '/leads'}>Leads</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>

              <Link to="/reports">
                <Styled.MenuContainer active={location.pathname === '/reports'}>
                  {location.pathname === '/reports' ?
                    <FilterFilled style={{color: 'white'}} />
                    :
                    <FilterOutlined style={{color: '#047294'}} />
                  }
                  <Styled.MenuLabel active={location.pathname === '/reports'}>Reports</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>

              {/*<Link to="/">
                <Styled.MenuOption active={location.pathname === '/'}>
                  <DashboardOutlined style={{ color: '#fcd533' }} />
                  <Styled.MenuLabel>Dashboard</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>

              <Link to="/salaryDetails">
                <Styled.MenuOption active={location.pathname === '/salaryDetails'}>
                  <DatabaseOutlined />
                  <Styled.MenuLabel>Salary Details</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>
              <Link to="/addBuddyData">
                <Styled.MenuOption active={location.pathname === '/addBuddyData'}>
                  <SolutionOutlined />
                  <Styled.MenuLabel>Add Buddy Data</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>

              <Link to="/buddyinfo">
                <Styled.MenuOption active={location.pathname === '/buddyinfo' || location.pathname === '/buddyData'}>
                  <SolutionOutlined style={{ color: '#fcd533' }} />
                  <Styled.MenuLabel>Buddy Details</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>*/}

              {/* <Link to="/expenseDetails">
                <Styled.MenuOption active={location.pathname === '/expenseDetails'}>
                  <SolutionOutlined />
                  <Styled.MenuLabel>Expense Details</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link> */}

              {/* Uncomment the below code if required */}
              {/*<Link to="/addContract">
                <Styled.MenuOption active={location.pathname === '/addContract'}>
                  <FileAddOutlined />
                  <Styled.MenuLabel>Add Contract</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>

              <Styled.SidebarSubMenu>Venue Management</Styled.SidebarSubMenu>

              <Link to="/contractInfo">
                <Styled.MenuOption active={location.pathname === '/contractInfo'}>
                  <FileAddOutlined style={{ color: '#fcd533' }} />
                  <Styled.MenuLabel>Contracts</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>

              <Link to="/collection">
                <Styled.MenuOption active={location.pathname === '/collection'}>
                  <DollarCircleOutlined style={{ color: '#fcd533' }} />
                  <Styled.MenuLabel>Collection Verification</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>*/}
            </Styled.SidebarItems>
          </Styled.SidebarMenuContainer>

          <Popconfirm
            title="Are you sure to logout?"
            onConfirm={logout}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Styled.SidebarFooter>
              <Styled.SidebarItemLabel>
                <UserOutlined />
                <Styled.Username> {localStorage.getItem('user_name')} </Styled.Username>
              </Styled.SidebarItemLabel>
              <LogoutOutlined className="sidebar-item-icon" />
            </Styled.SidebarFooter>
          </Popconfirm>
        </Styled.SidebarContainer>
      </Styled.SidebarContainer>
    </Styled.Sidebar>
  );
}

export default SideBar;
