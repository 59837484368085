import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,Link,useParams} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input,Empty,Breadcrumb } from 'antd';
import { ArrowRightOutlined, HomeOutlined, BankFilled } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell} from 'recharts';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;

const COLORS = ['#39b351','#0088FE', '#FFBB28', '#FF8042', '#FF6F61', '#6A67CE', '#00C49F'];

const CollectionSummary = () => {
  const navigate = useNavigate();
  const { venue_id } = useParams();
  var local_selectedVenueId = venue_id
  var local_month = JSON.parse(localStorage.getItem('month'));
  var local_year = JSON.parse(localStorage.getItem('year'));

  var local_game_type = JSON.parse(localStorage.getItem('gameType'))
  if(!local_game_type){
    local_game_type = 8
  }

  const [loading, setLoading] = useState(true)
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [collectionData, setCollectionData] = useState([])
  const [collectionDetails, setCollectionDetails] = useState([])
  const [header, setHeader] = useState([])
  const [month, setMonth] = useState(local_month)
  const [year, setYear] = useState(local_year)
  const [gameType, setGameType] = useState(local_game_type)
  const [venueName, setVenueName] = useState('')
  const [graphData, setGraphData] = useState({})
  const [pieGraphData, setPieGraphData] = useState([])
  const [totalCollection, setTotalCollection] = useState('')

  useEffect(() => {
    setLoading(true)
    // getCollection()
    getCollectionGraph()
    getCollectionSplit()
    getCollectionDaybyDay()
  }, [month,year,selectedVenueId,gameType]); 

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };  

  const getCollection = () => {
    getData(`/business/get_collection_overview/?month=${month}&year=${year}&venue_id=${selectedVenueId}&game_type_id=${gameType}`)
    .then(response =>{
      setCollectionData(response.result)
      setVenueName(response.result[0].venue)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const getCollectionSplit = () => {
    getData(`/business/get_collection_split_up_by_mode/?month=${month}&year=${year}&venue_id=${selectedVenueId}&game_type_id=${gameType}`)
    .then(response =>{
      setPieGraphData(response.result)
      setTotalCollection(response.total_collection)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const getCollectionGraph = () => {
    getData(`/business/get_dashboard_graph/?month=${month}&year=${year}&venue_id=${selectedVenueId}&game_type_id=${gameType}`)
    .then(response =>{
      setGraphData(response.result)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const getCollectionDaybyDay = () => {
    var date = year + '-' + month
    getData(`/eagleeye/getTableDataJSON/?report_id=752338&venue_id=${selectedVenueId}&date=${date}`)
    .then(response =>{
      setCollectionDetails(response.data)
      setHeader(response.headers)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const formatIndianCurrency = (value) => {
    return Number(value).toLocaleString('en-IN')
  };

  const columns = [
    {
      title: 'Venue',
      render: (_, record) => {
      const { venue, venue_id} = record;
      return (
          <Link to={`/collectionList/${venue_id}`} className='venueTitle'>
            {
              venue
            }
          </Link>
          )
      },
    },

    {
      title: 'Collection',
      dataIndex: 'collection',
      render: (value) => {
        return value.toLocaleString('en-IN')
      },
      align: 'right'
    },

    {
      title: 'Regular',
      dataIndex: 'academy',
      render: (value) => {
        return value.toLocaleString('en-IN')
      },
      align: 'right'
    },

    {
      title: 'Advanced',
      dataIndex: 'advanced',
      render: (value) => {
        return value.toLocaleString('en-IN')
      },
      align: 'right'
    },

    {
      title: 'Elite',
      dataIndex: 'elite',
      render: (value) => {
        return value.toLocaleString('en-IN')
      },
      align: 'right'
    },

    {
      title: '',
      dataIndex: 'venue_id',
      render: (value) => {
        return(
          <Link to={`/collectionList/${value}`} className='venueTitle'>
            <ArrowRightOutlined/>
          </Link>
          )
      }
    },
  ]

  // const collectionColumns = header.map(col => ({
  //   title: col.charAt(0).toUpperCase() + col.slice(1).replace(/_/g, ' '),
  //   dataIndex: col,
  //   key: col,
  // }));

  const collectionColumns = [
    {
      title: 'Day',
      dataIndex: 'Day',
      align: 'center'
    },
    {
      title: 'No of Tickets',
      dataIndex: 'Tickets',
      render: (value) => {
        return value.toLocaleString('en-IN')
      },
      align: 'right'
    },
    {
      title: 'Football Academy',
      dataIndex: 'Academy-F',
      render: (value) => {
        return value.toLocaleString('en-IN')
      },
      align: 'center'
    },
    {
      title: 'Badminton Academy',
      dataIndex: 'Academy-B',
      render: (value) => {
        return value.toLocaleString('en-IN')
      },
      align: 'center'
    },
    {
      title: 'Swimming Academy',
      dataIndex: 'Swimming',
      render: (value) => {
        return value.toLocaleString('en-IN')
      },
      align: 'center'
    },
    {
      title : 'Kit Fee',
      dataIndex: 'Kit Fee',
      render: (value) => {
        return value.toLocaleString('en-IN')
      },
      align: 'center'
    }
  ]

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.TopDiv>    
          <Breadcrumb style={{padding:'10px'}} >
            <Breadcrumb.Item>
              <Link to="/">
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to="/collection">
                <BankFilled />
                <span style={{paddingLeft:'5px'}}>Collection</span>
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{venueName}</Breadcrumb.Item>
          </Breadcrumb>

          <div style={{display: 'flex'}}>
             <Styled.Selector>
              {/*<span className='indicatorText'>Game Type</span>*/}
              <Select
                  className = 'mainSelector'
                  style={{width: 210}}
                  onChange={setGameType}
                  value = {gameType}
                  bordered={false}
                >
                  <Option value="8"> Football Academy </Option>
                  <Option value="10"> Badminton Academy </Option>
                  <Option value="65"> Swimming Academy </Option>

                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <Select
                  className = 'mainSelector'
                  style={{width: 150}}
                  onChange={setMonth}
                  value = {month}
                  bordered={false}
                >
                  <Option value="12"> December </Option>
                  <Option value="11"> November </Option>
                  <Option value="10"> October </Option>
                  <Option value="09"> September </Option>
                  <Option value="08"> August </Option>
                  <Option value="07"> July </Option>
                  <Option value="06"> June </Option>
                  <Option value="05"> May </Option>
                  <Option value="04"> April </Option>
                  <Option value="03"> March </Option>
                  <Option value="02"> February </Option>
                  <Option value="01"> January </Option>
                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <div className = 'mainSelector'>
                <DatePicker defaultValue={moment(year, 'YYYY')}  onChange = {onChangeYear}  bordered={false}  picker="year" />
              </div> 
            </Styled.Selector>
          </div>
        </Styled.TopDiv>

        <Styled.Header>          
          <span> Collection - {venueName}</span>
        </Styled.Header>

      <Styled.GraphContainer>

        <Styled.GraphDiv>
          <Styled.GraphDivLabelDiv>
            <Styled.LabelHeading> Collection - Analysis </Styled.LabelHeading>

            <Styled.GraphMonthSelector>
              Last 12 months
            </Styled.GraphMonthSelector>
          
          </Styled.GraphDivLabelDiv>

          <ResponsiveContainer width="100%" height="80%">
            <LineChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis tickFormatter={formatIndianCurrency}/>
              <Tooltip formatter={(value, name) => [formatIndianCurrency(value), name]}/>
              <Legend />
              <Line type="monotone" dataKey="collection" stroke="#02475c" />
            </LineChart>
          </ResponsiveContainer>
        </Styled.GraphDiv>

        <span></span>
         <Styled.GraphDiv>
          <Styled.GraphDivLabelDiv>
            <Styled.LabelHeading> Payment Mode Split Up </Styled.LabelHeading>
          </Styled.GraphDivLabelDiv>
            {
              totalCollection > 0 ?              
                <Styled.PieDiv>
                              
                  <div style={{position: 'relative'}}>
                    <PieChart width={200} height={200}>
                      <Pie
                        data={pieGraphData}
                        innerRadius={60}
                        // outerRadius={80}
                        fill="#8884d8"
                        // paddingAngle={5}
                        dataKey="collection"
                      >
                        {pieGraphData.map((entry, index) => (
                          <Cell key={`cell-${entry}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                    <Styled.TotalAmount> {totalCollection.toLocaleString('en-IN')} </Styled.TotalAmount>
                  </div>                              
              
                  <Styled.PieDivInfo>
                    {
                      pieGraphData.map((item,index)=>(
                        <Styled.InfoRow>
                          <Styled.InfoColor style={{backgroundColor: COLORS[index % COLORS.length]}} />
                          <Styled.InfoLabel> {item.payment_mode} - {item.collection.toLocaleString('en-IN')}</Styled.InfoLabel>
                        </Styled.InfoRow>
                      ))
                    }  
                  </Styled.PieDivInfo>
              
                </Styled.PieDiv>
              :
               <Empty style={{marginTop: '10%'}} description = {<span style={{color: 'grey', fontSize: 'small'}}> data not available </span>} />
            }

        </Styled.GraphDiv>
      </Styled.GraphContainer>

        {/*<Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={collectionData}
          pagination={false}
         />*/}

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={collectionColumns}
          dataSource={collectionDetails}
          pagination={false}
         />

      </Styled.MainContainer>
    </Spin>
  );
}

export default CollectionSummary;