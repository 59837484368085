import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate, Link} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input } from 'antd';
import { ArrowRightOutlined, RiseOutlined, FallOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar} from 'recharts';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;

const Customers = () => {

  var today = new Date();
  var local_month = JSON.parse(localStorage.getItem('month'));
  if(local_month){
   var mm = local_month
  }else{
      var mm = String(today.getMonth() + 1).padStart(2, '0')
  }

  var yyyy = today.getFullYear()
  yyyy = yyyy.toString()

  var local_city = JSON.parse(localStorage.getItem('cityId'))
  var local_selectedVenueId = JSON.parse(localStorage.getItem('selectedVenueId'))
  if(!local_selectedVenueId){
    local_selectedVenueId = 'All'
  }

  var local_game_type = JSON.parse(localStorage.getItem('gameType'))
  if(!local_game_type){
    local_game_type = 8
  }

  const [loading, setLoading] = useState(true)
  const [venues, setVenues] = useState([])
  const [cities, setCities] = useState([])
  const [cityId, setCityId] = useState(local_city)
  const [venuelist, setVenuelist] = useState([])
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [month, setMonth] = useState(mm)
  const [year, setYear] = useState(yyyy)
  const [gameType, setGameType] = useState(local_game_type)
  const [customerData, setCustomerData] = useState([])
  const [graphData, setGraphData] = useState({})
  const [chartData, setChartData] = useState({})
  const [maxVal, setMaxVal] = useState('')

  useEffect(() => {
    localStorage.setItem('month', JSON.stringify(month));
    localStorage.setItem('year', JSON.stringify(year));
    localStorage.setItem('selectedVenueId',JSON.stringify(selectedVenueId))
    localStorage.setItem('cityId',JSON.stringify(cityId))
    localStorage.setItem('gameType',JSON.stringify(gameType))
  }, [month,year,selectedVenueId,cityId,gameType]);

  useEffect(() => {
     getVenues()
  }, []); 

  useEffect(() => {
    if(cityId !== ''){
      setLoading(true)
      getCustomerDetails()
      getRevenueGraph()
    }
  }, [month,year,selectedVenueId,cityId,gameType]); 

  useEffect(() =>{
    if(cityId !== ''){
      getCustomerGraph()
    }
  }, [selectedVenueId,cityId,gameType])

  useEffect(() => {
    getVenuesInCity()
  }, [cities,cityId]);

  const getVenuesInCity =() =>{
    if(cities[0] && !cityId){
      setCityId(cities[0].id)
    }
    if(cityId == 'All'){
      setVenuelist(venues)
    }
    else{
      setVenuelist( 
        venues.filter((item)=>{
              return item.city === cityId
            })
      ) 
    }
    setSelectedVenueId('All')
  }

  const getVenues = async () => {
    await getData('/business/get_venues_for_buddies/')
    .then(response =>{
      setVenues(response.result)
      setCities(response.cities)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };

  const getCustomerDetails = () => {
    getData(`/business/get_customers_overview/?month=${month}&year=${year}&venue_id=${selectedVenueId}&city_id=${cityId}&game_type_id=${gameType}`)
    .then(response =>{
      setCustomerData(response.result)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }


  const getCustomerGraph = () => {
    var venue = ''
    if(selectedVenueId == 'All'){
      if(cityId){
        var venue = cityId
      }
    }
    else{
      var venue = selectedVenueId
    }

    if(venue == 'All'){
      var venue = 0
    }

    getData(`/eagleeye/getTableDataJSON/?report_id=76371&venue_id=${venue}&game_type_id=${gameType}`)
    .then(response =>{
      // setChartData(response.data)
      calculateYaxis(response.data)      
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const getRevenueGraph = () => {
    getData(`/business/get_dashboard_graph/?month=${month}&year=${year}&venue_id=${selectedVenueId}&city_id=${cityId}&game_type_id=${gameType}`)
    .then(response =>{
      setGraphData(response.result)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const calculateYaxis = (data) => {
    if(data && data.length > 0){
      const maxVal = Math.max(
        ...data.map(item => Math.max(item.new_customers, item.renewals))
      );
      setMaxVal(Math.ceil(maxVal / 10) * 10);

      const reversedData = data.reverse();
      setChartData(reversedData)
    }
  };

  const replaceCharacter = (value) => {
    if(Math.sign(value) == '-1'){
      return value.toString().replace('-','')
    }
    else{
      return value
    }
  }

  const columns = customerData.length > 0 ? 
    [
      ...Object.keys(customerData[0]).filter(key => key !== 'venue_id' && key !== 'percent_diff').map(key => ({
        title: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),  
        dataIndex: key,
        key: key,
        align: key !== 'venue' && 'center',
        render: key === 'venue' ? 
                  (text, record) => (
                    <Link to={`/customers/${record.venue_id}`} className='venueTitle'>
                      {record.venue}
                    </Link>
                  )
                : key === 'customers' ? 
                  (text, record) => (
                    <div style={{display:'grid',gridTemplateColumns: '1fr auto'}}>                    
                      <Link to={`/customerList/?venue_id=${record.venue_id}`} className='venueTitle'>{text}</Link>                      
                      {
                        (record.percent_diff || record.percent_diff == 0) ?
                          <Styled.PercentDiv style={{backgroundColor: Math.sign(record.percent_diff) == '1' ? 'rgb(233 255 232)' : 'rgb(255 232 232)'}}>
                            {
                              Math.sign(record.percent_diff) == '1' ?
                                <CaretUpOutlined  style={{color:  'green'}}/> 
                              :
                                <CaretDownOutlined  style={{color: 'red'}}/>
                            }
                            <div style={{color: Math.sign(record.percent_diff) == '1' ? 'green' : 'red',fontSize:'small'}}> {replaceCharacter(record.percent_diff) + '%'} </div>
                          </Styled.PercentDiv>
                        :
                        <Styled.PercentDiv style={{backgroundColor: 'transparent'}}></Styled.PercentDiv>
                      }                      
                    </div>
                  )
                : key === 'renewed' || key === 'new' || key === 'expired' ?
                  (text, record) => (
                    <Link to={`/customerList/?venue_id=${record.venue_id}&type=${key}`} className='venueTitle'>
                      {text}
                    </Link>
                  )
                : 
                text => text
        })),
      {
        title: '',
        dataIndex: 'venue_id',
        render: (value) => {
          return(
            <Link to={`/customers/${value}`} className='venueTitle'>
              <ArrowRightOutlined/>
            </Link>
            )
        }
      }
    ]
  : 
    [];

  const formatXAxis = (value) => {
    return moment(value).format('MMM')
  };

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.TopDiv>    
          <Styled.SelectorContainer>

            {cities.length > 1 &&         
              <Styled.Selector>
                <span className='indicatorText'>City</span>
                <Select
                  className = 'mainSelector'
                  style={{width: 150}}
                  showSearch = {true}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                  onChange={setCityId}
                  value = {cityId}
                  bordered={false}
                >
                  <Option value="All" name="All"> All </Option>
                  {
                    cities.map(item=>(
                      <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                    ))
                  }
                </Select>
              </Styled.Selector>
            }

            <Styled.Selector>
              <span className='indicatorText'>Venue</span>
              <Select
                className = 'mainSelector'
                style={{width: 300}}
                showSearch = {true}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={setSelectedVenueId}
                value = {selectedVenueId}
                bordered={false}
              >
                <Option value="All" name="All"> All </Option>
                {venuelist.map(item=>(
                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                  ))
                }
              </Select>
            </Styled.Selector>

            <Styled.Selector>
              <span className='indicatorText'>Program</span>
              <Select
                  className = 'mainSelector'
                  style={{width: 210}}
                  onChange={setGameType}
                  value = {gameType}
                  bordered={false}
                >
                  <Option value="8"> Football Academy </Option>
                  <Option value="10"> Badminton Academy </Option>
                  <Option value="65"> Swimming Academy </Option>
                </Select>
            </Styled.Selector>
          </Styled.SelectorContainer>

          <div style={{display: 'flex'}}>
            <Styled.Selector>
              <span className='indicatorText'>Month</span>
              <Select
                  className = 'mainSelector'
                  style={{width: 150}}
                  onChange={setMonth}
                  value = {month}
                  bordered={false}
                >
                  <Option value="12"> December </Option>
                  <Option value="11"> November </Option>
                  <Option value="10"> October </Option>
                  <Option value="09"> September </Option>
                  <Option value="08"> August </Option>
                  <Option value="07"> July </Option>
                  <Option value="06"> June </Option>
                  <Option value="05"> May </Option>
                  <Option value="04"> April </Option>
                  <Option value="03"> March </Option>
                  <Option value="02"> February </Option>
                  <Option value="01"> January </Option>
                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <span className='indicatorText'>Year</span>
              <div className = 'mainSelector'>
                <DatePicker defaultValue={moment(year, 'YYYY')}  onChange = {onChangeYear}  bordered={false}  picker="year" />
              </div>
            </Styled.Selector>
          </div>
        </Styled.TopDiv>

        <Styled.Header>          
          <span> Customers - Overview</span>
        </Styled.Header>
      
      <Styled.GraphContainer>

        <Styled.GraphDiv>
          <Styled.GraphDivLabelDiv>
            <Styled.LabelHeading> Active Customers - Analysis </Styled.LabelHeading>

            <Styled.GraphMonthSelector>
              Last 12 months
            </Styled.GraphMonthSelector>
          
          </Styled.GraphDivLabelDiv>

          <ResponsiveContainer width="100%" height="80%">
            <LineChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="active customers" stroke="#02475c" name="Active Customers" />
            </LineChart>
          </ResponsiveContainer>
        </Styled.GraphDiv>
        
        <span></span>
        
        <Styled.GraphDiv>
          <Styled.GraphDivLabelDiv>
            <Styled.LabelHeading> Renewals & New Customers - Analysis </Styled.LabelHeading>

            {/*<Styled.GraphMonthSelector>
              Last 12 months
            </Styled.GraphMonthSelector>*/}
          
          </Styled.GraphDivLabelDiv>

          <ResponsiveContainer width="100%" height="80%">
            <LineChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" tickFormatter={formatXAxis}/>
              <YAxis domain={[0, maxVal]} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="new_customers" stroke="#008080" name="New Customers"  />
              <Line type="monotone" dataKey="renewals" stroke="#4B0082" name="Renewals" />
            </LineChart>
          </ResponsiveContainer>
        </Styled.GraphDiv>

      </Styled.GraphContainer>

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={customerData}
          pagination={{ pageSize: 20, showSizeChanger: false}}
         />

      </Styled.MainContainer>
    </Spin>
  );
}

export default Customers;