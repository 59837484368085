import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate, Link} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar} from 'recharts';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;

const Revenue = () => {

  var today = new Date();
  var local_month = JSON.parse(localStorage.getItem('month'));
  if(local_month){
   var mm = local_month
  }else{
      var mm = String(today.getMonth() + 1).padStart(2, '0')
  }

  var yyyy = today.getFullYear()
  yyyy = yyyy.toString()

  var local_city = JSON.parse(localStorage.getItem('cityId'))
  var local_selectedVenueId = JSON.parse(localStorage.getItem('selectedVenueId'))
  if(!local_selectedVenueId){
    local_selectedVenueId = 'All'
  }

  var local_game_type = JSON.parse(localStorage.getItem('gameType'))
  if(!local_game_type){
    local_game_type = 8
  }

  const [loading, setLoading] = useState(true)
  const [venues, setVenues] = useState([])
  const [cities, setCities] = useState([])
  const [cityId, setCityId] = useState(local_city)
  const [venuelist, setVenuelist] = useState([])
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [month, setMonth] = useState(mm)
  const [year, setYear] = useState(yyyy)
  const [gameType, setGameType] = useState(local_game_type)
  const [revenueData, setRevenueData] = useState([])
  const [graphData, setGraphData] = useState({})

  useEffect(() => {
    localStorage.setItem('month', JSON.stringify(month));
    localStorage.setItem('year', JSON.stringify(year));
    localStorage.setItem('selectedVenueId',JSON.stringify(selectedVenueId))
    localStorage.setItem('cityId',JSON.stringify(cityId))
    localStorage.setItem('gameType',JSON.stringify(gameType))
  }, [month,year,selectedVenueId,cityId,gameType]);

  useEffect(() => {
     getVenues()
  }, []); 

  useEffect(() => {
    if(cityId !== ''){
      setLoading(true)
      getRevenueDetails()
      getRevenueGraph()
    }    
  }, [month,year,selectedVenueId,cityId,gameType]); 

  useEffect(() => {
    getVenuesInCity()
  }, [cities,cityId]);

  const getVenuesInCity =() =>{
    if(cities[0] && !cityId){
      setCityId(cities[0].id)
    }
    if(cityId == 'All'){
      setVenuelist(venues)
    }
    else{
      setVenuelist( 
        venues.filter((item)=>{
              return item.city === cityId
            })
      ) 
    }
    setSelectedVenueId('All')
  }

  const getVenues = async () => {
    await getData('/business/get_venues_for_buddies/')
    .then(response =>{
      setVenues(response.result)
      setCities(response.cities)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };

  const getRevenueDetails = () => {
    getData(`/business/get_revenue_overview/?month=${month}&year=${year}&venue_id=${selectedVenueId}&city_id=${cityId}&game_type_id=${gameType}`)
    .then(response =>{
      setRevenueData(response.result)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const getRevenueGraph = () => {
    getData(`/business/get_dashboard_graph/?month=${month}&year=${year}&venue_id=${selectedVenueId}&city_id=${cityId}&game_type_id=${gameType}`)
    .then(response =>{
      setGraphData(response.result)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const formatIndianCurrency = (value) => {
    return Number(value).toLocaleString('en-IN')
  };

  const columns = [
    {
      title: 'Venue',
      render: (_, record) => {
      const { venue, venue_id} = record;
      return (
          <Link to={`/revenueList/${venue_id}`} className='venueTitle'>
            {
              venue
            }
          </Link>
          )
      },
    },

    {
      title: 'Total',
      render: (_, record) => {
      const { revenue, customers} = record;
      return (
          <div>
            <div style={{fontWeight:900}}>{revenue.toLocaleString('en-IN')}</div>
            <div style={{color:'grey'}}>{customers}</div>
          </div>
        )
      },
      align: 'right'
    },

    {
      title: 'Regular',
      render: (_, record) => {
      const { academy, academy_count} = record;
      return (
          <div>
            <div style={{fontWeight:900}}>{academy.toLocaleString('en-IN')}</div>
            <div style={{color:'grey'}}>{academy_count}</div>
          </div>
        )
      },
      align: 'right'
    },

    {
      title: 'Advanced',
      render: (_, record) => {
      const { advanced, advanced_count} = record;
      return (
          <div>
            <div style={{fontWeight:900}}>{advanced.toLocaleString('en-IN')}</div>
            <div style={{color:'grey'}}>{advanced_count}</div>
          </div>
        )
      },
      align: 'right'
    },

    {
      title: 'Elite',
      render: (_, record) => {
      const { elite, elite_count} = record;
      return (
          <div>
            <div style={{fontWeight:900}}>{elite.toLocaleString('en-IN')}</div>
            <div style={{color:'grey'}}>{elite_count}</div>
          </div>
        )
      },
      align: 'right'
    },

    {
      title: '',
      dataIndex: 'venue_id',
      render: (value) => {
        return(
          <Link to={`/revenueList/${value}`} className='venueTitle'>
            <ArrowRightOutlined/>
          </Link>
          )
      }
    },
    
  ]

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.TopDiv>    
          <Styled.SelectorContainer>

            {cities.length > 1 &&         
              <Styled.Selector>
                <span className='indicatorText'>City</span>
                <Select
                  className = 'mainSelector'
                  style={{width: 150}}
                  showSearch = {true}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                  onChange={setCityId}
                  value = {cityId}
                  bordered={false}
                >
                  <Option value="All" name="All"> All </Option>
                  {
                    cities.map(item=>(
                      <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                    ))
                  }
                </Select>
              </Styled.Selector>
            }

            <Styled.Selector>
              <span className='indicatorText'>Venue</span>
              <Select
                className = 'mainSelector'
                style={{width: 300}}
                showSearch = {true}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={setSelectedVenueId}
                value = {selectedVenueId}
                bordered={false}
              >
                <Option value="All" name="All"> All </Option>
                {venuelist.map(item=>(
                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                  ))
                }
              </Select>
            </Styled.Selector>

            <Styled.Selector>
              <span className='indicatorText'>Program</span>
              <Select
                  className = 'mainSelector'
                  style={{width: 210}}
                  onChange={setGameType}
                  value = {gameType}
                  bordered={false}
                >
                  <Option value="8"> Football Academy </Option>
                  <Option value="10"> Badminton Academy </Option>
                  <Option value="65"> Swimming Academy </Option>

                </Select>
            </Styled.Selector>
          </Styled.SelectorContainer>

          <div style={{display: 'flex'}}>
            <Styled.Selector>
              <span className='indicatorText'>Month</span>
              <Select
                  className = 'mainSelector'
                  style={{width: 150}}
                  onChange={setMonth}
                  value = {month}
                  bordered={false}
                >
                  <Option value="12"> December </Option>
                  <Option value="11"> November </Option>
                  <Option value="10"> October </Option>
                  <Option value="09"> September </Option>
                  <Option value="08"> August </Option>
                  <Option value="07"> July </Option>
                  <Option value="06"> June </Option>
                  <Option value="05"> May </Option>
                  <Option value="04"> April </Option>
                  <Option value="03"> March </Option>
                  <Option value="02"> February </Option>
                  <Option value="01"> January </Option>
                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <span className='indicatorText'>Year</span>
              <div className = 'mainSelector'>
                <DatePicker defaultValue={moment(year, 'YYYY')}  onChange = {onChangeYear}  bordered={false}  picker="year" />
              </div>
            </Styled.Selector>
          </div>
        </Styled.TopDiv>

        <Styled.Header>          
          <span> Revenue & Active Customers - Overview</span>
        </Styled.Header>
      
      <Styled.GraphContainer>

        <Styled.GraphDiv>
          <Styled.GraphDivLabelDiv>
            <Styled.LabelHeading> Revenue - Analysis </Styled.LabelHeading>

            <Styled.GraphMonthSelector>
              Last 12 months
            </Styled.GraphMonthSelector>
          
          </Styled.GraphDivLabelDiv>

          <ResponsiveContainer width="100%" height="80%">
            <LineChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis tickFormatter={formatIndianCurrency} />
              <Tooltip formatter={(value, name) => [formatIndianCurrency(value), name]}/>
              {/*<Legend />*/}
              <Line type="monotone" dataKey="revenue" stroke="#c48e1a" name="Revenue" />
            </LineChart>
          </ResponsiveContainer>
        </Styled.GraphDiv>
        
        <span></span>
        
        <Styled.GraphDiv>
          <Styled.GraphDivLabelDiv>
            <Styled.LabelHeading> Active Customers - Analysis </Styled.LabelHeading>

            <Styled.GraphMonthSelector>
              Last 12 months
            </Styled.GraphMonthSelector>
          
          </Styled.GraphDivLabelDiv>

          <ResponsiveContainer width="100%" height="80%">
            <BarChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis tickFormatter={formatIndianCurrency} />
              <Tooltip formatter={(value, name) => [formatIndianCurrency(value), name]}/>
              {/*<Legend />*/}
              <Bar type="monotone" dataKey="active customers" fill="#016a8b" name="Active Customers"/>
            </BarChart>
          </ResponsiveContainer>
        </Styled.GraphDiv>

      </Styled.GraphContainer>

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={revenueData}
          pagination={{ pageSize: 20, showSizeChanger: false}}
         />

      </Styled.MainContainer>
    </Spin>
  );
}

export default Revenue;